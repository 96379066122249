import React from 'react'

const AVisionForYou = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > A Vision For You </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                            Our book is meant to be suggestive only. We realize we know only a little. God will
                            constantly disclose more to you and to us. Ask Him in your morning meditation what you
                            can do each day for the man who is still sick. The answers will come, if your own house
                            is in order. But obviously you cannot transmit something you haven’t got. See to it that
                            your relationship with Him is right, and great events will come to pass for you and
                            countless others. This is the Great Fact for us.</p>
                        <p>
                            Abandon yourself to God as you understand God. Admit your faults to Him and to your
                            fellows. Clear away the wreckage of your past. Give freely of what you find and join us.
                            We shall be with you in the Fellowship of the Spirit, and you will surely meet some of us
                            as you trudge the Road of Happy Destiny.</p>
                        <p>
                            May God bless you and keep you—until then.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default AVisionForYou
import React from 'react';
import { Outlet } from "react-router-dom";
import ScrollToTop from './utils/ScrollToTop';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

// import logo from './logo.svg';
import './App.css';


const App = () => (
  <React.Fragment>
  <ScrollToTop />
      <div className="wrapper">
      <Header />
      <Outlet></Outlet>
      <Footer />
    </div>
    </React.Fragment>
);

export default App;

import React from 'react'

const TheFirst30Days = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > The First 30 Days </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>Welcome to Cocaine Anonymous. We are all here for the same reason—our inability to stop using cocaine and all other mind-altering substances. The first step towards solving any problem is admitting that there is a problem.</p>
                                    <p><b>THE PROBLEM</b><br></br>The problem, as we see it, consists of an obsession of the mind and an allergy of the body. The obsession is a continued and irresistible thought of cocaine and the next high. Once we have given in to this thought, our bodies take over. The allergy creates an absolute inability to stop using once we begin. Addiction is not a moral issue. Addiction is a disease—a disease that kills.</p>
                                    <p><b>THE SOLUTION</b><br></br>We wish to assure you that there is a solution and that recovery is possible. It begins with complete abstinence from cocaine and all other mind-altering substances. It continues with practicing C.A.’s Twelve Steps of recovery, one day at a time. Here are some suggestions to help you stay clean and sober for your first 30 days:</p>
                                    <p><b>Abstinence: </b>Do not use any mind-altering substances! Experience has shown that the use of any mind-altering substance will ultimately lead us back to the drug we were trying to avoid or to addiction in another form.</p>
                                    <p><b>A Meeting a Day: </b>Attend meetings as often as possible, preferably every day. Meetings are where we go to share our experience, strength, and hope with each other.</p>
                                    <p><b>Get a Sponsor: </b>It is a good idea to get a sponsor during your early days, when C.A. seems unfamiliar. A sponsor shares one-on-one how to maintain sobriety by working the Twelve Steps of Cocaine Anonymous.</p>
                                    <p><b>Use the Telephone: </b>Get phone numbers from other C.A. members and call them between meetings. A vital part of our recovery process is building a support network of sober friends. Reaching out to others can make all the difference in maintaining our sobriety. If no one on your phone list is available, call your local C.A. helpline.</p>
                                    <p><b>One Day at a Time: </b>We stay clean and sober one day at a time and, when necessary, one hour or even one minute at a time; not for one week, or one month, or one year—just one day at a time.</p>
                                    <p>Cocaine and other mind-altering substances helped us escape from ourselves; they altered our perception of reality. They helped us cover up, avoid, and deaden our feelings. As we get clean and sober, our feelings begin to surface. Recovery can be challenging, but with help, we find our lives get better one day at a time.</p>
                                    <p>When we attended our first C.A. meeting, we knew deep down inside that cocaine, alcohol and/or other drugs had become a problem in our lives. Acknowledging this was just the beginning. This is where the program of Cocaine Anonymous provides a solution. We begin by surrendering and working the Twelve Steps of Cocaine Anonymous.</p>
                                    <p><b>STEP ONE: </b>We admitted we were powerless over cocaine and all other mind-altering substances—that our lives had become unmanageable.</p>
                                    <p>Most of us disliked the idea of being powerless over anything. We thought cocaine, alcohol and/or other drugs made us invincible and powerful. In reality, they took a toll on us financially, emotionally, physically, and spiritually. Many of us were out of control and had reached the depths of despair. The extent was different for each of us, but the fact remained that our lives had become unmanageable. It was not until we got honest with ourselves and surrendered that we began to know peace.</p>
                                    <p><b>STEP TWO: </b>Came to believe that a Power greater than ourselves could restore us to sanity.</p>
                                    <p>Step Two involves open-mindedness. Having admitted we were powerless over cocaine and all other mind-altering substances, we became open-minded enough to believe that a Power greater than ourselves could remove our obsession to use and restore us to sanity. This Power may be God, but does not have to be. Many of us use the Fellowship of C.A. as our Higher Power. After all, what we had failed to do alone, we are succeeding in doing together.</p>
                                    <p><b>STEP THREE: </b>Made a decision to turn our will and our lives over to the care of God <i>as we understood Him.</i></p>
                                    <p>Cocaine Anonymous is a spiritual program, not a religious one. Some of us arrived with a belief in God, while others used the group until they found a Higher Power. A key phrase in this Step is <i>"as we understood Him."</i> In Cocaine Anonymous, each individual can choose a God of his or her own understanding.</p>
                                    <p>As we continue to work the Twelve Steps of Cocaine Anonymous, we begin to see these Promises coming true in our lives:</p>
                                    <p><i>If we are painstaking about this phase of our development, we will be amazed before we are halfway through. We are going to know a new freedom and a new happiness. We will not regret the past nor wish to shut the door on it. We will comprehend the word serenity and we will know peace. No matter how far down the scale we have gone, we will see how our experience can benefit others. That feeling of uselessness and self-pity will disappear. We will lose interest in selfish things and gain interest in our fellows. Self-seeking will slip away. Our whole attitude and outlook upon life will change. Fear of people and of economic insecurity will leave us. We will intuitively know how to handle situations which used to baffle us. We will suddenly realize that God is doing for us what we could not do for ourselves.*</i></p>
                                    <p>* This excerpt from Alcoholics Anonymous, pages 83-84, is reprinted with permission of Alcoholics Anonymous World Services, Inc. (“AAWS”). Permission to use this excerpt does not mean that AAWS has reviewed or approved the contents of this publication, or that AAWS necessarily agrees with the views expressed therein. A.A. is a program of recovery from alcoholism only—use of this material in connection with programs and activities which are patterned after A.A., but which address other problems or concerns, or in any other non-A.A. context, does not imply otherwise.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/The-First-30-Days.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default TheFirst30Days
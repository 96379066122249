import React from "react";
import { Link } from 'react-router-dom';
import Modal from "../Modal/Modal";

class Literature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "",
      modalContent: "",
      modalShow: false
    };
  }

  _setModal = (img, type ,e) => {
    e.preventDefault();
    this.setState({ 
        modalType: type,
        modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalShow;
      return { modalShow: newState };
    });
  };

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <h1 className="title"> Readings From Our Literature </h1>
            <div className="columns">
              <div className="column">
              <div className="content box has-background-white">
              <h2 className="title"> Meeting Readings </h2>
              <ul>
              <li><a href="/files/literature/manifest.pdf" target="_blank">Meeting Formats w/ Readings</a></li>
              <li><Link to="/literature/ca_preamble">CA Preamble</Link></li>
              <li><Link to="/literature/we_can_recover">We Can Recover</Link></li>
              <li><Link to="/literature/the_twelve_traditions">The Twelve Traditions</Link></li>
              <li><Link to="/literature/who_is_a_cocaine_addict">Who Is An Cocaine Addict?</Link></li>
              <li><Link to="/literature/who_is_a_ca_member">Who Is A C.A. Member?</Link></li>
              <li><Link to="/literature/the_promises">The Promises</Link></li>
              <li><Link to="/literature/a_vision_for_you">A Vision For You</Link></li>
              <li><Link to="/literature/reaching_out">Reaching Out</Link></li>
              <li><Link to="/literature/serenity_prayer">The Serenity Prayer</Link></li>
              <li><a href="/files/literature/CA-Secretary-Script.pdf" target="_blank">Suggested Secretary Script</a></li>
              </ul>
              <h2 className="title"> AA literature </h2>
              <ul>
                <li><a href="/" alt="leaving" onClick={(e) => this._setModal('https://www.aa.org/pages/en_US/alcoholics-anonymous','leaving', e)}>AA Big Book</a></li>
                <li><a href="/" alt="leaving" onClick={(e) => this._setModal('https://www.aa.org/pages/en_US/twelve-steps-and-twelve-traditions','leaving', e)}>AA Twelve Steps and Twelve Traditions</a></li>
              </ul>
              </div>
              </div>
              <div className="column">
              <div className="content box has-background-white">
              <h2 className="title"> Pamplets </h2>
              <ul>
                <li><Link to="/literature/and_all_other_mind_altering_substances">...And All Other Mind Altering Substances</Link></li>
                <li><Link to="/literature/12_principles">12 Principles</Link>	</li>
                <li><Link to="/literature/guide_to_the_12_steps">A Guide to The 12 Steps</Link></li>
                <li><Link to="/literature/a_higher_power">A Higher Power</Link></li>
                <li><Link to="/literature/a_message_to_professionals">A Message to Professionals</Link></li>
                <li><Link to="/literature/a_new_high_from_hi">New High from H &amp; I</Link></li>
                <li><Link to="/literature/anonymity">Anonymity</Link></li>
                <li><Link to="/literature/being_a_gsr">Being a GSR</Link></li>
                <li><Link to="/literature/being_of_service">Being of Service</Link></li>
                <li><Link to="/literature/ca_is_also_for_the_lgbtq_plus_addict">C.A. is Also for the LGBTQ+ Addict</Link></li>
                <li><Link to="/literature/ca_lifeline">C.A. Lifeline</Link></li>
                <li><Link to="/literature/choosing_your_sponsor">Choosing A Sponsor</Link></li>
                <li><Link to="/literature/cocaine_anonymous_self_test">Cocaine Anonymous Self-Test</Link></li>
                <li><Link to="/literature/crack">Crack</Link></li>
                <li><Link to="/literature/dos_donts_for_12th_step_calls_for_addicts">Dos &amp; Don’ts for 12th-Step Calls for Addicts</Link></li>
                <li><Link to="/literature/how">H.O.W. – Honesty, Open-Mindedness and Willingness</Link></li>
                <li><Link to="/literature/having_fun_in_recovery">Having Fun in Recovery</Link></li>
                <li><Link to="/literature/reaching_out_to_the_deaf_and_hard_of_hearing">Reaching out to the Deaf and Hard of Hearing</Link></li>
                <li><Link to="/literature/the_7th_tradition">The 7th Tradition</Link></li>
                <li><Link to="/literature/the_first_30_days">The First 30 Days</Link></li>
                <li><Link to="/literature/the_home_group">The Home Group</Link></li>  
                <li><Link to="/literature/tips_for_staying_clean_sober">Tips for Staying Clean &amp; Sober</Link></li>
                <li><Link to="/literature/to_the_newcomer">To The Newcomer</Link></li>
                <li><Link to="/literature/too_young_to_recover">Too Young to Recover?</Link></li>
                <li><Link to="/literature/tools_of_recovery">Tools of Recovery</Link></li>
                <li><Link to="/literature/unity">Unity</Link></li>
                <li><Link to="/literature/what_is_ca">What is C.A.?</Link></li>
                <li><Link to="/literature/yes_you_can_start_a_ca_meeting">Yes, You Can Start a C.A. Meeting</Link></li>
              </ul>
              </div>
              </div>
            </div>
          </div>
        </section>
        <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} openLink={this._openLink} modalState={this.state.modalShow} />
      </React.Fragment>
    );
  }
}

export default Literature;

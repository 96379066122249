import React from 'react'

const How = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > H.O.W. – Honesty, Open-Mindedness and Willingness </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>Honesty, Open-Mindedness and Willingness</b></p>
                                    <p>Welcome to Cocaine Anonymous. We are all here for the same reason—our inability to stop using cocaine and all other mind-altering substances. We wish to assure you that there is a solution and that recovery is possible. Many of us have found that to begin our journey from the misery, terror, and pain of addiction to the solution of recovery, it was necessary to embrace three spiritual principles: honesty, open-mindedness and willingness.</p>
                                    <p><b>Honesty</b></p>
                                    <p>When we arrived, many of us knew very little about being honest. We had practiced being dishonest for so long, it had become a way of life. For months and years, we had built walls around us of falsehoods and fantasy. Of these, the most personally destructive was denial. We denied we had a serious problem with drugs and alcohol. We told ourselves that we could manage our lives, that we were in control, or that other people were to blame for our problems. When we finally arrived at the point where our dishonesty no longer served us, we had reached the end of the line.</p>
                                    <p>With the help of our sponsors, we realized that for us to move from the problem of addiction to the solution of recovery, we needed to admit and embrace the truth about ourselves. Once we began to see ourselves as we really are, we found that perhaps change was in order . . . but how were we to go about it? What we discovered along the way was that to change our thoughts, we had to begin to act our way into a new way of thinking. We were not saints, and the miracle within us was not performed overnight, but when we began to practice honesty, our journey of recovery was well underway.</p>
                                    <p>The path to being rigorously honest for most of us is a lifetime of work. We can only gauge our honesty today by comparing it to our honesty yesterday. Once we began to act and, therefore, think differently, we found that we could practice being honest with ourselves, which enabled us to also receive the truth from others.</p>
                                    <p>Most of us had tried on our own to do everything we knew to get clean and sober, and we failed miserably. We never seemed to get it right. However, as we reflected honestly on the thoughts and actions of our using careers, we finally saw how much our perception of the world differed from reality. Once we learned to practice honesty, our recovery could progress.</p>
                                    <p><b>Open-Mindedness</b></p>
                                    <p>After admitting that we had been truly defeated by our substance use and that we could not manage our own lives, we were left facing another obstacle: our beliefs. Although we arrived here from different ethnicities and cultures, many of us realized that our old belief systems were not enough on their own to deliver us from our addiction. Regardless of how we prayed, or if we prayed at all, it seemed we could not tap into a power sufficient to overcome our cravings and our desire to use. Some of us refused to believe in a Higher Power; others of us became convinced that ours had abandoned us. It was suggested that we seek a Higher Power that could keep us clean and restore our sanity. Regardless of our spiritual state, we had to first give up some of our old ideas and preconceptions in order to become willing to believe that this was possible.</p>
                                    <p>When we reached this critical point and became willing to believe, our minds began to open. We listened with excitement as our sponsors and our fellows spoke about the solution that they had found! For the first time, we truly believed that it might be possible for us to have the answers we had sought but were never able to find on our own. We had finally become ready to take a leap of faith, which would direct us out of the darkness. Slowly, our attitudes began to change, and we began to have hope.</p>
                                    <p><b>Willingness</b></p>
                                    <p>During our active addiction, many of us acted in ways that were perhaps unsavory or even horrendous. Those actions often got us what we wanted: the next high. While we didn’t all hit the same bottom, we recognized that our arrival there was the result of our own bad decisions, which may have seemed good at the time. We sank to those depths through our selfish and self-centered thinking in pursuit of the next binge or bender. When we began working a program of recovery, including the 12 Steps, and embracing the truth about ourselves, we realized just how negatively our actions had affected our lives and those around us. Having embraced honesty and open-mindedness, realizing that the old ways of living would no longer serve us or others, we had to stop making excuses for our actions.</p>
                                    <p>All around us, we began to see the freedom that other members had gained, and we wanted what they had. They told us that recovery would be given to us freely, but that we would need to be ready to do the work ourselves. We were told our journey would not always be easy, but that it would be worth it. We may not have trusted ourselves at this point, but we learned to listen to what others had to say and to trust the process.</p>
                                    <p>Just as we prepared for the actions that fed our addictions, we also had to prepare for the actions that would feed our recovery. Willingness is what precedes and sustains those actions. It begins when we take the position of readiness and prepare to work. We become open to the will of our Higher Power and accepting of the guidance from our sponsor and fellows, trusting that we will not be led astray. Armed with the truth and the belief that we could be restored to sanity, we let nothing stand in our way. This new-found willingness prepared us mentally, physically, and spiritually to begin to take action, putting one foot in front of the other to embark upon the journey of recovery.</p>
                                    <p>Honesty, open-mindedness and willingness are three spiritual principles at the foundation of our recovery. Without them, a solution cannot be reached. They are not items on a list to be checked off, one at a time. Rather, they become characteristics of our new lease on life. As we become more open-minded, we also become more willing. As our willingness increases, so does our honesty. Our progress is not measured by comparing ourselves to others—only to our previous selves. As we learn to embrace these principles and practice them in all our affairs, the pathway to freedom begins to shine before us.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                &nbsp;
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default How
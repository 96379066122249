import React from 'react';
import moment from "moment-timezone";
import Modal from '../Modal/Modal';

class Events extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modalType: '',
      modalContent: '',
      modalShow: false,
      events : []
    }
  }

  componentDidMount(){

    //get events data
    fetch('/data/events-data.json'
      ,{
        headers: {
          'Context-Type' : 'application/json'
        }
      })
    .then(response => {
      return response.json();
    })
    .then(eventsJson => {

      //sort by date
      let sortedEvents = this._getUpcomingEvents(eventsJson).sort((a,b) => {
        let dateA = ''
        let dateB = ''
        //either DateTimeStart or Date
        if(a['DateTimeStart'])
          dateA = new Date(a['DateTimeStart']);
        else
          dateA = new Date(a['Date']);

        if(b['DateTimeStart'])
          dateB = new Date(b['DateTimeStart']);
        else
          dateB = new Date(b['Date']);

        if(dateA < dateB)
          return -1;
        if(dateA > dateB)
          return 1;
        
        return 0;
      });

      //append Recurring meetings to list
      sortedEvents = sortedEvents.concat(eventsJson.filter((event) => {
        if(event.Recurring)
          return true;
        else 
          return false;
      }))

      this.setState({
        events: sortedEvents
      })

    })

    window.scrollTo(0, 0);
  }

  _getUpcomingEvents = (events) => {

    let now =  moment();

    let upcomingEvents = events.filter((event) => {

      //check event for the following criteria:
      //1. DateTimeStart has passed
      //2. Date has passed

      //search DateTimeStart or Date
      if(event.DateTimeStart){
        if(event.DateTimeEnd){
          return moment(event.DateTimeEnd).isAfter(now) !== false;
        } else
        return moment(event.DateTimeStart).isAfter(now) !== false;
      }
      else
      {
        return (moment(event.Date).isAfter(now) !== false || moment(event.Date).isSame(now.date+1) !== false);
      }

    });

    return upcomingEvents;

  };

  _setModal = (img, type ,e) => {
    e.preventDefault();
    this.setState({ 
        modalType: type,
        modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props)=> {
      const newState = !prev.modalShow;
      return { modalShow : newState}
    });
  }

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  }

  _getEventDate = (event) => {

    let rtndate = '';

    if(event.Recurring){
      return rtndate = event.RecurringSchedule;
    } 
    else if (event.DateTimeStart){
      rtndate = moment(event.DateTimeStart).format('LLLL');
      if(event.DateTimeEnd){
        //show only time if less than 24 hrs
        let start = new moment(event.DateTimeStart);
        let end = new moment(event.DateTimeEnd);
        let diffHours = end.diff(start, 'hours');
        if(diffHours > 23){
          console.log(diffHours > 23)
          end = moment(event.DateTimeEnd).format('LLLL');
          
          return rtndate += ' - ' + end;
        } 
        else{
          console.log('here')
          return rtndate += '-' + moment(event.DateTimeEnd).format('LT');
        }
      }
    } 
    else {
      //rtndate = moment(event.Date).format('LL');
    }

    return rtndate;
  }

  render(){
    return (
      <React.Fragment>
      <section className="section">
          <div className = "container" >
            <h1 className = "title" > Upcoming Events </h1>
            {this.state.events.map((event,i) => (
              <React.Fragment key={i}>
              <div className="columns is-centered">
                <div className="column">
                  <div className={`box has-background-white ${event.Recurring ? "recurring" : ""}`}>
                    {event.Recurring &&
                      <h4 className="ribbon">Monthly</h4>                    
                    }
                    <h2><strong>{event.Name}</strong></h2><br></br>
                    <p>{this._getEventDate(event)} 
                    </p>

                    <p><br></br></p>
                    <p>Location:</p>
                    {event.LocationLink ?
                      <p><a href="0" onClick={(e) => this._setModal(event.LocationLink,'leaving', e)}>{event.Location}</a></p>
                    : <p>{event.Location}</p>
                    }
                    {event.LocationLink2 ?
                      <p><a href="0" onClick={(e) => this._setModal(event.LocationLink2,'leaving', e)}>{event.Location2}</a></p>
                    : ''
                    }
                    {event.LocationDetails &&    
                    <p>{event.LocationDetails}</p>
                    }
                    <p><br></br></p>
                    <p className="content">{event.Details}</p>
                    {event.Website &&                   
                    <p><a href="0" onClick={(e) => this._setModal(event.Website.URL,'leaving', e)}>{event.Website.Text}</a></p>
                    }
                    {event.Flyer &&   
                    <p><a target="blank" href={event.Flyer}>Flyer</a></p>
                    }
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              </React.Fragment>
            ))}
          </div > 
        </section>
        <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} openLink={this._openLink} modalState={this.state.modalShow} />
        </React.Fragment>
    )
  }
}

export default Events;
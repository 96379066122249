import React from 'react'

const ANewHighFromHI = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > A New High from H&amp;I </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>Our primary purpose is to carry the message of recovery to the addict who still suffers.</b><br></br>One way we do this is by holding meetings in hospitals and institutions for people who are not able to attend outside meetings. Institutions served may include, but not be limited to, correctional facilities, sanitariums, detox units, juvenile detention centers, half-way houses and shelters, either governmental or private. Confinement may be voluntary or involuntary.</p>
                                    <p>Through working with others in H&amp;Is, members of Cocaine Anonymous share their experience, strength and hope. Below are some of our feelings about out H&amp;I experiences.</p>
                                    <p><b>Remembering Where We Came From</b><br></br><i>“My first experience with doing H&amp;I work was when I had 90 days sober and went to speak on a panel at a hospital. I was sure I had nothing to offer, nothing to say. I sat there listening to the speakers before me and knew they had said everything I wanted to say. When it was my turn, I just opened my mouth and let the words pour out. I not only said what someone in the room needed to hear, but things that I needed to hear.”</i></p>
                                    <p><i>"When the meeting was over, I spent some time talking to the patients and shared some more of my hope, faith and courage. I left that meeting feeling a sense of happiness and joy I never felt before. I still feel that high whenever I speak on a panel, especially to a group of adolescents."</i></p>
                                    <p><i>"H&amp;I helps me to remember where I came from. It also allows me to watch the miracle of recovery change others and in doing so, it allows me to change myself. There are no words that can describe the feeling inside when someone I first met at an H&amp;I panel gets his/her first year token."</i></p>
                                    <p><i>"Being a recovering addict, the most dangerous thing for me is to forget that I am an addict or to think that I can use successfully, but that is exactly what my addiction tries to get me to believe. Whenever I go into a detox meeting, I am always reminded of the simple truth of addiction and its consequences. This helps me to stay sober and to be grateful for my recovery. It was passed through this method to me, and I feel blessed to be able to carry on the tradition."</i></p>
                                    <p><b>Gratitude</b><br></br> <i>"Leaving the correctional facility, I feel ecstatic; grateful for the privilege of being a vehicle of my Higher Power and hopeful that a seed may have been planted in the mind of even one still-suffering addict. I share the miracle of my recovery and how Cocaine Anonymous has changed my life; H&amp;I service work helps me to stay clean and sober today. This is one of the ways it works for me. Through service in H&amp;I, my gratitude is multiplied."</i></p>
                                    <p><i>"As a parent, when I leave a youth lockdown facility, I thank God that it wasn’t one of my children listening to the panel. Most of all, I feel grateful that I am sober and carrying the message of C.A. to those who are not so fortunate."</i></p>
                                    <p><i>“My reason for H&amp;I is a selfish one: to stay clean and remain grateful for what I have. It offers me a feeling of usefulness to God and to mankind.”</i></p>
                                    <p><i>"The look in the patients’ eyes, the sweat on their foreheads and on the palms of their hands; they’re not sure if they can stay sober another day. That makes you feel grateful because when the meeting is over, you’re going home. That’s the only difference between you and them."</i></p>
                                    <p><i>“Gratitude …<br></br>Doors opening instead of closing,<br></br>being able to give it away<br></br>the hunger for recovery<br></br>The newcomer in a hospital or institution … gives me humility and constant realization of hope.”</i></p>
                                    <p><b>Hope</b><br></br> <i>"It is my belief that sharing my experience, strength and hope through H&amp;Is, I hope that I may in some small way help another suffering addict see a glimmer of hope and a better way of life. But for the grace of God … there go I."</i></p>
                                    <p><i>“When I speak on an H&amp;I panel, the feeling that something special is going on is immediate. The patients’ or inmates’ eyes light up as I’m telling my story. They’ve been where I’ve been and have felt what I’ve felt … hopelessness. Now they’re sitting in a hospital or jail, wondering ‘Where do I go from here?’ As I share the path my recovery has taken, I see at times the look of hope re-enter their faces as they think ‘Maybe this will work for me, too.’ I feel great sharing my hope, faith and courage with the addicts who need it most.”</i></p>
                                    <p><b>A New High from H&amp;I</b><br></br> <i>“What do I get out of H&amp;I? Being affiliated with H&amp;I has given me a broader outlook on who I am as a person, because I have to give it away to keep it. Just being able to walk in and out of institutions is a blessing."</i></p>
                                    <p><i>"During my 26 years that I used on a daily basis, I never experienced the euphoria that I enjoyed last month. While attending a C.A. panel two years ago in a center for the Department of Corrections, I observed a new inmate in complete denial of his disease. During the next year and a half, I received the gift of watching this man grow through the Twelve Steps. He was released to a half-way house where C.A. holds another panel, and both of us participated on that panel. Last month, he attended the H&amp;I Committee meeting and was placed on a panel that is going back to the same jail in which we met. That same night, he picked up his 18-month chip."</i></p>
                                    <p><i>"When people look at me today, laughing and cheerful, many ask me what I’m up to. My response is consistently, ‘I’m working with others.'"</i></p>
                                    <p>If you like what you’ve read here, identify with the feelings being shared, and want to feel similar feelings, you can attend your local H&amp;I committee meeting and participate in your own recovery by helping others.</p>
                                    <p><b>Cocaine Anonymous</b><br></br><b>"We’re Here and We're Free"&trade;</b></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/A-New-High-from-HI.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ANewHighFromHI
import React from 'react'

const Copyright = () => (
  <section className="section">
    <div className="container" >
      <h1 className="title" > Copyright &amp; Usage </h1>
      <div className="columns">
        <div className="column">
          <div className="box has-background-white content">
            <p>
              This World Wide Web site (www.canewyork.org, www.canewyork.com, www.ca-ny.org) is a publication of Cocaine Anonymous® World Services, Inc. ("CAWS") and CA New York ("CANY"). All material, including, without limitation, trademarks, copyrights and all other rights, presented or included herein, including all subsidiary pages, is owned and/or copyrighted by CAWS, by CANY, Inc, and by the current webmaster. Permission is granted to download and store this material for individual, non-proprietary use only. All other rights are fully reserved. Cocaine Anonymous is not affiliated with any other Anonymous organization.
          </p>
            <p>
              We regret being unable to offer links to non-C.A. sites, due to our longstanding tradition of non-endorsement of outside enterprises, whether related to our goals or not. While we are grateful for all links from other Web sites, we do not endorse any product, service or opinion offered by any outside organization.
          </p>
            <p>
              "C.A.", "Cocaine Anonymous", "We're here and we're free" and the C.A. Logo are registered trademarks of Cocaine Anonymous World Services, Inc. All rights reserved.
          </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Copyright
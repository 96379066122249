import React from 'react'

const DosDontsFor12thStepCallsForAddicts = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Dos &amp; Don’ts for 12th-Step Calls for Addicts </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>A Twelfth-Step call is one way for members of Cocaine Anonymous to be of service to others. We should not hesitate to go anywhere to be helpful, while keeping in mind the hopelessness and despair of active addiction. Provided we are spiritually fit, we can offer help to a still-suffering addict at any place and at any time.</p>
                                    <p>Since helping others is paramount to our program of recovery, please keep in mind these suggestions of what to <b><i>do:</i></b>
                                    </p>
                                    <p>
                                        <ul>
                                            <li>Always take at least one other person on a Twelfth-Step call and whenever possible, meet the prospect in a public place or at a meeting.</li>
                                            <li>Be certain that you can remain safe from physical harm.</li>
                                            <li>It is suggested that men make Twelfth-Step calls on men and women make Twelfth-Step calls on women. For purposes of this pamphlet, we refer to the prospect with masculine pronouns, but the suggestions are intended to apply to both men and women.</li>
                                            <li>Bring a phone and be ready to call for emergency medical assistance if necessary.</li>
                                            <li>Bring Hope, Faith &amp; Courage Volume I and/or Volume II, C.A. pamphlets, a Big Book, and a list of local C.A. meetings with you.</li>
                                            <li>Ask your prospect if he wants to stop using and is willing to go to any length to quit. Start the conversation in a general way to establish a rapport, and then turn the discussion to some aspect of using.</li>
                                            <li>Encourage your prospect to tell you about himself. Assure him that you will maintain his anonymity. If he won’t talk, discuss your using career until the point where you quit. Emphasize the troubles using has caused you.</li>
                                            <li>Talk to the prospect about the disease of addiction. Focus on your personal experience and the hopelessness of this fatal illness. Make it clear that he does not have to agree with your concept of a Higher Power.</li>
                                            <li>Describe C.A.’s Twelve-Step program of recovery and explain how it is working for you.</li>
                                            <li>Offer your friendship and make it clear that if he has a desire to stop using you will do whatever you can to help.</li>
                                            <li>Let your prospect know that this process benefits you because helping others is a vital part of your recovery. It gets you out of yourself and helps you stay sober.</li>
                                            <li>Remember that you are there to offer assistance and guidance. Let your prospect know that he owes you nothing; your only hope is that he will help others when he has gained and maintained sobriety.</li>
                                        </ul>
                                    </p>
                                    <p>Here are a few serious suggestions we hope you’ll remember regarding what we <b><i>don’t do:</i></b>
                                    </p>
                                    <p>
                                        <ul>
                                            <li>Never go alone to make a Twelfth-Step call.</li>
                                            <li>Never jeopardize your own safety or sobriety.</li>
                                            <li>If you have been convicted of a felony or are currently on probation or parole, you may not want to perform a Twelfth-Step call. (Most felony convictions don’t allow felons to consort with active drug users. You may want to consult an attorney.)</li>
                                            <li>Don’t bring any weapons.</li>
                                            <li>Never meet your prospect at a dope house or in a bar.</li>
                                            <li>Don’t deal with the prospect when he is high unless there is a danger of overdose or the family needs your help. You would be wasting your time trying to persuade him to stop using.</li>
                                            <li>Don’t loan your prospect money or move him into your home. There are other resources available to help newcomers transition successfully into the community.</li>
                                            <li> Don’t give him other drugs (to postpone withdrawal and/or counteract the ones he may be using). 9. Remember that we are not doctors, so the diagnosis is not up to you—only the help.</li>
                                            <li> It is unwise to approach a prospect unless you have some background from either his family or close friends—but don’t call his employer, spouse, family or the police as a way of leveraging him into recovery.</li>
                                            <li>At this point, most addicts who are using do not want to listen to evangelism or criticism, so be careful not to use religious verbiage or theological terms, no matter what your convictions may be.</li>
                                            <li> Never force yourself on the prospect. If you are asked not to come back, don’t go back. It will not be helpful to contradict your prospect if he shows resistance to working the Steps. There is no need to pressure him into making a decision right there.</li>
                                            <li>Don’t spend too many hours on a single Twelfth-Step call. If the prospect does not have a desire to get sober, seek out another who does want your help.</li>
                                        </ul>
                                    </p>
                                    <p>Remember, if our motives are correct, and we are making the Twelfth-Step call to help bring a suffering addict into recovery, we don’t have to be afraid. We can rely on our Higher Power to keep us safe and protected.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/dos-donts-calls.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default DosDontsFor12thStepCallsForAddicts
import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import Modal from '../Modal/Modal';

class PI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: '',
      modalContent: '',
      modalShow: false
    };
  }

  customRenderItem = (item, props) => {
    //check if video still playing and not selected
    return <item.type {...item.props} {...props} />;
  }

  getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

  getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

  customRenderThumb = (children) =>
    children.map((item, i) => {
      //check for video or image
      if (item.props.url && item.props.type === "youtube") {
        const videoId = this.getVideoId(item.props.url);
        return <img key={i} alt={item.props.alt} src={this.getVideoThumb(videoId)} />;
      }
      else {
        return <img key={i} alt={item.props.alt} src={item.props.src} />;
      }

    });

  _setModal = (img, type, e) => {
    e.preventDefault();
    this.setState({
      modalType: type,
      modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalShow;
      return { modalShow: newState }
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container" >
            <h1 className="title" > Public Information </h1>
            <div className="columns">
              <div className="column">
                <div className="box has-background-white content">
                  <p>The Public Information Committee of Cocaine Anonymous of New York develops, initiates, and plans the means of communication to the public, within the guidelines of the C.A. World Service Manual. We do this through the following means:</p>
                  <ul>
                    <li>Communicating with the media</li>
                    <li>Communicating with professionals working in addiction and recovery</li>
                    <li>Communicating with courts, law enforcement, parole offices, and probation offices</li>
                    <li>Preparing radio and television public service announcements</li>
                    <li>Hosting information booths at fairs and events</li>
                    <li>Using posters to inform the public about C.A.</li>
                  </ul>
                  <p className="subtitle">New York PSA's</p>
                  <Carousel renderItem={this.customRenderItem} renderThumbs={this.customRenderThumb}>
                    <MediaPlayer type="youtube" alt="Street Posting" url="https://www.youtube.com/embed/yv5qGqrqA04" />
                    <img alt="Street Posting" src="files/pi/Street-posting-1.jpg" />
                    <img alt="Street Posting" src="files/pi/Street-posting-2.jpg" />
                    <img alt="Highway Posting" src="files/pi/Highway-posting.jpg" />
                    <img alt="Subway Posting" src="files/pi/Subway-posting.jpg" />
                    <MediaPlayer type="radio" alt="Radio PSA" src="files/pi/radio-psa.jpg" url="files/pi/CA-PSA1-NEWYORK.mp3" />
                    <MediaPlayer type="radio" alt="Radio PSA" src="files/pi/radio-psa.jpg" url="files/pi/CA-PSA2-NEWYORK.mp3" />
                  </Carousel>
                </div>
              </div>
            </div>
          </div >
        </section>
        <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} modalState={this.state.modalShow} />
      </React.Fragment>
    );
  }
}

const MediaPlayer = ({ url, isSelected, type }) => {

  const divStyle = {
    padding: 20
  };

  if(isSelected){
    if(type === "youtube")
      return <ReactPlayer width="100%" height="100%" url={url} playing={false} />
    else
      return <ReactPlayer style={divStyle} width="100%" height="100%" url={url} playing={false} controls={true} />

  }
  else return null;
};

export default PI;
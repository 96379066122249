import React from 'react'

const ReachingOut = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Reaching Out </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                            I made it into this program because someone else worked their Twelfth Step on me.
                            Someone passed it on to me. Someone was out there after they got clean and sober,
                            caring about others. I need to never, ever forget that. Had they simply gone on with their
                            lives and forgotten about people like me who were still out there using and suffering, I
                            wouldn’t be here today. My gratitude begins with that fact. It is with that gratitude in
                            mind that I reach out to others, especially the newcomers. I need to have them in my
                            life. That is where my spirituality begins.</p>
                        <p>
                            For me, spirituality comes from caring about others. I have found that the more I focus
                            on improving the quality of the lives of others, the less I am into myself and my will. I
                            feel a freedom and peace from within. The gifts I am beginning to receive in my life are
                            greater than I could have ever imagined.</p>
                        <p>
                            Something else I have done is that I have forgiven myself. I have forgiven myself for
                            being an addict. I have forgiven myself for all the damage I did to my life, to my physical
                            health, and to my career and finances. But most of all, I have forgiven myself for all of
                            the horrible, negative and unloving things I have felt about myself. It was not until I
                            offered and accepted my own forgiveness, that I was truly able to grow in my sobriety.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ReachingOut
import React from 'react'

const ToTheNewcomer = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > To the Newcomer </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>Who is a Cocaine Addict?</b></p>
                                    <p>Some of us can answer without hesitation, “I am!” Others aren’t so sure. Cocaine Anonymous believes that no one can decide for another whether he or she is addicted. One thing is sure, though: every single one of us has <i>denied</i> being an addict. For months, for years, we who now freely admit that we are cocaine addicts thought that we could control cocaine when in fact it was controlling us.</p>
                                    <p>
                                    “I only use on weekends,” or<br></br>
                                    “It hardly ever interferes with work,” or<br></br>
                                    “I can quit, it’s only psychologically addicting, right?” or<br></br>
                                    “I only snort, I don’t base or shoot,” or<br></br>
                                    “It’s this relationship that’s messing me up.”<br></br>
                                    </p>
                                    <p>Many of us are still perplexed to realize how long we went on, never getting the same high we got at the beginning, yet still insisting, and believing—so distorted was our reality—that we were getting from cocaine what actually always eluded us. We went to any lengths to get away from being ourselves. The lines got fatter; the grams went faster; the week’s stash was all used up today. We found ourselves scraping envelopes and baggies with razor blades, scratching the last flakes from the corners of brown bottles, snorting or smoking any white speck from the floor when we ran out. We, who prided ourselves on our fine-tuned state of mind! Nothing mattered more to us than the straw, the pipe, the needle. Even if it made us feel miserable, we had to have it.</p>
                                    <p>Some of us mixed cocaine with alcohol or other drugs and found temporary relief in the change, but in the end, it only compounded our problems. We tried quitting by ourselves, finally, and managed to do so for periods of time. After a month, we imagined we were in control. We thought our system was cleaned out and we could get the old high again, using half as much. This time, we’d be careful not to go overboard. But we only found ourselves back where we were before, and worse.</p>
                                    <p>We never left the house without using first. We didn’t make love without using. We didn’t talk on the phone without coke. We couldn’t fall asleep; sometimes it seemed we couldn’t even breathe without cocaine. We tried changing jobs, apartments, cities, lovers—believing that our lives were being screwed up by circumstances, places, people. Perhaps we saw a cocaine friend die of respiratory arrest, and still we went on using! But eventually we had to face facts. We had to admit that cocaine was a serious problem in our lives, that we were addicts.</p>
                                    <p><b>What Brought Us to Cocaine Anonymous?</b></p>
                                    <p>Some of us hit a physical bottom. It may have been anything from a nosebleed which frightened us, to sexual impotence, to loss of sensation in or temporary paralysis of a limb, to a loss of consciousness and a trip to an emergency room, to a cocaine-induced stroke that left us disabled. Maybe it was finally our gaunt reflection in the mirror.</p>
                                    <p>Others of us hit an emotional or spiritual bottom. The good times were gone, the coke life was over. No matter how much we used, we never again achieved elation, only a temporary release from the depression of coming down, and often, not even that. We suffered violent mood swings. Perhaps we awoke to our predicament after threatening or actually harming a loved one, desperately demanding imagined hidden money. We were overcome by feelings of alienation from friends, loved ones, parents, children, society, from the sky, from everything wholesome. Even the dealer we thought was our friend turned into a stranger when we went to him without money. Perhaps we awoke in dread of the isolation we had created for ourselves—using alone, suffocated by our self-centered fear and our paranoia. We were spiritually and emotionally deadened. Perhaps we thought of suicide, or tried it.</p>
                                    <p>Still others of us reached a different sort of bottom when our spending and lying cost us our jobs, credit, and possessions. Some of us reached the point where we couldn’t even deal; we consumed everything we touched before we could sell it. We simply could no longer afford to use. Sometimes the law intervened.</p>
                                    <p>Most of us were brought down by a medley of financial, physical, social, and spiritual problems.</p>
                                    <p>When we found Cocaine Anonymous, we learned that addiction is a progressive disease, chronic and potentially fatal. We were relieved to be told that addiction is not simply a moral problem, that it is a true disease over which the will alone is usually powerless. All the same, each of us must take responsibility for our own recovery. There is no secret, no magic. We each have to quit and stay sober; but we don’t have to do it alone!</p>
                                    <p><b>What is Cocaine Anonymous?</b></p>
                                    <p>We are a Fellowship of addicts who meet together to share our hope, faith, and courage for the purpose of staying sober and helping others achieve the same freedom. Everything heard at our meetings is to be treated as confidential. There are no dues or fees of any kind. To be a member, you only have to want to quit. We also exchange phone numbers, and give and seek support from one another between meetings.</p>
                                    <p>We are all on equal footing here. We are men and women of all ages, races and social backgrounds, with the common bond of addiction. The Twelve-Step program of Cocaine Anonymous is gratefully borrowed from Alcoholics Anonymous. Many years of experience have demonstrated the value of one addict helping another.</p>
                                    <p>We welcome newcomers to C.A. with more genuine warmth and acceptance in our hearts than you can probably now imagine—for you are the lifeblood of our program. In great part, it is by carrying the message of recovery to others like ourselves that we keep our own sobriety. We are all helping ourselves by helping each other.</p>
                                    <p><b>What is the First Thing?</b></p>
                                    <p>To the newcomer who wonders about the first thing he or she must do to achieve sobriety, we say that you have already done the first thing: you have admitted to yourself, and now to others, that you need help by the very act of coming to a meeting or seeking information about the C.A. program.</p>
                                    <p>You are at this very moment doing what is necessary to stay sober: you’re not getting loaded. Cocaine Anonymous is a one-day-at-a-time program. We suggest that you not dwell on wanting to stay sober for the rest of your life, or for a year, or even a week. Once you have decided you want to quit, let tomorrow take care of itself. But sometimes it is too much for us to project even one whole day drug-free. That’s okay. Take recovery a day at a time, an hour a time, or even a minute at a time if necessary.</p>
                                    <p>In the C.A. Fellowship, you are among addicts who are living without drugs. Make use of us! Take phone numbers. Between meetings, you may not be able to avoid contact with drugs and people who are still using. Some of us had no sober friends at all when we first came in. You have sober friends now! If you begin to feel restless, irritable and discontented, don’t wait. Give one of us a call, and don’t be surprised if one of us calls you when we need help!</p>
                                    <p>It may surprise you that we discourage the use of any mind-altering substances, including alcohol and marijuana. It is the common experience of addicts in this and other programs that any drug use leads to relapse or substitute addiction. If you’re addicted to another substance, you’d better take care of it. If you’re not, then you don’t need it, so why mess with it? We urge you to heed this sound advice drawn from the bitter experience of other addicts. Is it likely you’re different?</p>
                                    <p>We thought we were happy in our active addiction, but we were not. In C.A., we learn to live a new way of life. Cocaine Anonymous is a spiritual but not a religious program—our spiritual values are accessible to everyone.</p>
                                    <p>We who are grateful recovering addicts ask you to listen closely to our stories. That is the main thing—listen! We know where you’re coming from, because we’ve been there ourselves. Yet we are now living drug-free, not only that, but living happily; many of us, happier than we have ever been before.</p>
                                    <p>No one says that it is easy to arrest addiction. We had to give up old ways of thinking and behaving. We had to be <i>willing</i> to change. But we are doing it, gratefully, one day at a time.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/To-The-Newcomer.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ToTheNewcomer
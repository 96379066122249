import React from 'react'

const TwelvePrinciples = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > 12 Principles </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>The 12th Step of Cocaine Anonymous suggests that we "practice these principles in all our affairs." Principles can be defined as fundamental truths, beliefs, values, or philosophies. Living in accordance with spiritual principles can be a lifelong challenge, which is where the "practice" part comes in. The following list is by no means all-encompassing, but these are twelve essential principles of the C.A. program of recovery:</p>
                                    <p><b>HONESTY</b><br></br> Honesty is the ability to differentiate the true from the false. Becoming honest takes a willingness to change our perception. Getting honest is essential to sobriety and is a key element of making a beginning in our new way of life. In C.A, we are encouraged to be honest, open-minded and willing. Honesty is necessary if we are to be happy, joyous and free.</p>
                                    <p><b>HOPE</b><br></br>
                                    We find hope in the other recovering addicts who have come before us. Their stories tell us what it was like, what happened and what it is like today. Hope can be found in laughter, a hug, or the sparkle in someone’s eye. When we realize C.A. offers a way out, hope opens the door to truly connecting with our Higher Power.</p>
                                    <p><b>FAITH</b><br></br>
                                    Faith is a belief or trust in something. In C.A., it is suggested that we find a Higher Power of our own understanding. Faith is both a practice and a journey as we discover and develop our relationship with our Higher Power. For some, faith is hope with a track record, and thus many C.A. members choose to place their faith in the group until they are able to develop a relationship with their own Higher Power. Gradually, as a result of attending meetings and working the 12 Steps, we acquire an abundance of faith.</p>
                                    <p><b>COURAGE</b><br></br>
                                    Courage is the willingness to walk through our fears. It takes courage to walk through the doors of a C.A. meeting and ask for help. It takes courage to ask someone to sponsor us. With direction from that sponsor, we look at ourselves and our history to examine the causes, effects, and consequences of our actions. We develop the courage to change and to take the actions necessary to effect that change.</p>
                                    <p><b>INTEGRITY</b><br></br>
                                    Integrity results from using the principles of C.A. to guide our motives, intentions and actions. We strive to have our actions mirror our intentions. Working the Steps of Cocaine Anonymous helps us to build character. We demonstrate integrity when we are authentic, truthful and real.</p>
                                    <p><b>WILLINGNESS</b><br></br>
                                    Willingness is the key that opens the door to sobriety. We need willingness to let go of our old ideas. We surrender and become open to our Higher Power’s will for us. We become willing to go to any lengths to stay sober and to grow in our recovery.</p>
                                    <p><b>HUMILITY</b><br></br>
                                    Humility comes from understanding where our disease has taken us. We learn that we are not special or unique. We are finally able to see the truth about our addiction and our inability to stop using on our own. We make an effort to set aside all the things we think we know in order to become open to the sunlight of the spirit. Becoming humble is an opportunity for complete surrender to our Higher Power. We learn further humility by being of service to others.</p>
                                    <p><b>LOVE</b><br></br>
                                    In the beginning, others love us until we can love ourselves. As we work the Steps and our spirits heal, we become able to receive love. We begin showing love to others and to ourselves. The spirit of love reveals our true self, which is no longer hidden by the veil of addiction.</p>
                                    <p><b>FORGIVENESS</b><br></br>
                                    As we work the Steps, we develop the willingness to forgive ourselves and others. Through taking our own inventory, we discover our own part in situations and become more understanding toward others. Praying for those we resent and for the willingness to forgive sets us free. Forgiveness brings peace of mind and serenity.</p>
                                    <p><b>DISCIPLINE</b><br></br>
                                    Discipline is a new concept to many of us when we first get sober. With direction from our sponsor, we learn good recovery habits. We go to meetings regularly, read literature, work the Steps and get involved in service work. We develop a habit of checking our motives and looking at our actions on a regular basis. This is the beginning of what will become a way of life. These behaviors enhance our experience in sobriety.</p>
                                    <p><b>AWARENESS</b><br></br>
                                    Through regular inventory, we remain aware of our own motives, intentions and behaviors. Through prayer and meditation, we strive to improve our conscious contact with God as we understand Him. As we continuously seek spiritual growth, our awareness also grows.</p>
                                    <p><b>SERVICE</b><br></br>
                                    Service provides an opportunity to get out of ourselves and think of others. Being of service is a good way to share our experience, strength, and hope while enhancing our lives and the lives of others. It also helps us become useful members of society. Working with others for a greater good builds friendships, unity, and helps keep us sober. Service is how we give back what was so freely given to us and express our gratitude to Cocaine Anonymous.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/12-Principles.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default TwelvePrinciples
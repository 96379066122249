import moment from "moment-timezone";

function FormatMeetingTime (date, time) {
    const daysofWeek = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday'
    }
    return daysofWeek[date] + ', ' +  moment(time, 'HH:mm').format('h:mm A');
  }

export default FormatMeetingTime;
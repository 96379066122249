import React from 'react'

const YesYouCanStartACAMeeting = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Yes, You Can Start a C.A. Meeting </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><i>“Life will take on new meaning. To watch people recover, to see them help others, to watch loneliness vanish, to see a fellowship grow up about you, to have a host of friends—this is an experience you must not miss. We know you will not want to miss it. Frequent contact with newcomers and with each other is the bright spot of our lives.”*</i></p>
                                    <p><b>Desire &amp; Willingness</b><br></br>Have you ever thought about how a C.A. meeting gets started? Your hometown may have several C.A. meetings or perhaps only a few. They usually began out of a need to carry the message of recovery to others. In every case, someone made a decision to help Cocaine Anonymous grow by starting a meeting. It was often scheduled for a day or time when there was no other meeting available. People needed a place to go to share their experience, strength, and hope with others in recovery—others who had “been there” and who could understand. Someone found an available location and arranged to pay rent. Cocaine Anonymous World Services or the local Fellowship of C.A. was notified and a Starter Kit was provided. Or perhaps a local meeting donated chips and literature to help the new meeting get going. Someone made flyers and phone calls to get the word out that there was a new C.A. meeting starting. Meeting formats were printed and speakers were scheduled. Eventually, the group held its first business meeting, a name was chosen for the group, and trusted servants were elected.</p>
                                    <p>You can start a C.A. meeting, too. You already have the necessary tools. It takes willingness, commitment, and one or two people with a desire to stay sober and help others do the same. It doesn’t matter if the meeting is large or small. We are told that we “can’t keep what we have unless we give it away.”</p>
                                    <p>Starting a meeting can be accomplished by simply making a few telephone calls, showing up to open the door, and making a pot of tea or coffee.</p>
                                    <p><b>Get the Word Out</b><br></br>Once the location, day, and time for the new meeting have been established, the next task is to get the word out. If there is a local C.A. Fellowship, contact them to get the new meeting put into the directory and/or onto the Area website. Announce the newly scheduled meeting in other C.A. meetings and let it be known you’ll share details afterward with anyone needing directions, rides, or additional information. Flyers with all the pertinent details can be especially helpful. Let people know that the new meeting is for newcomers and old-timers alike and that support would be appreciated.</p>
                                    <p><b>Preparation</b><br></br>If there are no other C.A. meetings nearby, you can obtain a Starter Kit from the Cocaine Anonymous World Service Office via the contact information on the front of this pamphlet. Starter Kits include literature, chips, and a suggested meeting format with optional readings. <a href="/files/literature/manifest.pdf" target="_blank">Meeting formats can also be downloaded here</a>.</p>
                                    <p><b>Remember</b></p>
                                    <p>
                                        <ul>
                                            <li>Bring desire &amp; willingness;</li>
                                            <li>Contact your local C.A. Fellowship and/or C.A. World Services;</li>
                                            <li>Review the definition of a “Group” in the C.A. World Service Manual;</li>
                                            <li>Get chips and literature;</li>
                                            <li>Choose a meeting format;</li>
                                            <li>Set the day and time;</li>
                                            <li>Find and rent a location;</li>
                                            <li>Create flyers;</li>
                                            <li>Get the word out;</li>
                                            <li>Get a coffee maker or teapot;</li>
                                            <li>Get a basket for the 7th Tradition;</li>
                                            <li>Keep coming back.</li>
                                        </ul>
                                    </p>
                                    <p><b>Finally …</b><br></br>Tradition Five of Cocaine Anonymous tells us that our primary purpose is to carry the message of recovery to other addicts. Helping others and being of maximum service is our goal; it is the foundation of our recovery. Starting a meeting not only contributes to the growth of Cocaine Anonymous, it enhances your own recovery. The effort involved in starting a C.A. meeting is minimal, but the rewards are infinite.</p>
                                    <p><i>*This excerpt from Alcoholics Anonymous, page 89, is reprinted with permission of Alcoholics Anonymous World Services, Inc. (“AAWS”).to reprint this excerpt does not mean that AAWS has reviewed or approved the contents of this publication, or that AAWS necessarily agrees with the views expressed herein. A.A. is a program of recovery from alcoholism only—use of this excerpt in connection with programs and activities which are patterned after A.A., but which address other problems, or in any other non-A.A. context, does not imply otherwise.</i></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Start-a-CA-Meeting.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default YesYouCanStartACAMeeting
import React from 'react'

const AMessageToProfessionals = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > A Message to Professionals </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>Our goal is to inform you about Cocaine Anonymous and how our 12-Step program can help those with substance abuse issues recover from addiction.</p>
                                    <p className="is-size-5">WHAT IS COCAINE ANONYMOUS (C.A.)?</p>
                                    <p><b>C.A. IS</b> a global fellowship of recovering addicts whose members meet in local groups as well as via the Internet.</p>
                                    <p><b>C.A. IS</b> an international, community-based 12-Step program for daily recovery that has worked for many thousands of people worldwide.</p>
                                    <p><b>C.A. IS</b> helping its members achieve continuous abstinence from cocaine and all other mind-altering substances.</p>
                                    <p><b>C.A. IS NOT</b> drug specific, nor is it about any particular group of drugs.</p>
                                    <p><b>C.A. IS NOT</b> the only solution to drug addiction.</p>
                                    <p><b>C.A. IS NOT</b> affiliated with any other organization or funded by any third parties.</p>
                                    <p><b>C.A. IS NOT</b> a referral service.</p>
                                    <p className="is-size-5">HOW CAN WE HELP?</p>
                                    <p><b>Public Information:</b> We can provide literature, posters, and informative speakers to staff teams as well as host information booths at events. We can also cooperate with local and national media or other organizations to explain how C.A. works.</p>
                                    <p><b>Telephone Helpline:</b> Our telephone helplines are available to anyone looking for help or information. The helpline provides initial contact between the addict seeking help and a C.A. member.</p>
                                    <p><b>Health:</b> We can work with general practitioners, hospitals, treatment center staff, and others. We can offer a variety of help, from talks to staff teams to individual contact with clients with a substance misuse problem.</p>
                                    <p><b>Prisons and Treatment Centers:</b> We can arrange for C.A. members to visit on a regular basis and hold meetings in facilities where clients are unable to leave to attend regular meetings.</p>
                                    <p><b>Social and Family Services/Probation:</b> Certain meetings can provide ‘chits’ for attendees to use as evidence of attendance.</p>
                                    <p className="is-size-5">WHAT DOES COCAINE ANONYMOUS COST?</p>
                                    <p><b>C.A. is FREE.</b> Each C.A. group is fully self-supporting. Our expenses are covered by the voluntary contributions of our members, literature sales, and fundraising events such as conventions.</p>
                                    <p>C.A. respectfully declines donations/financial contributions from any outside source.</p>
                                    <p>WHAT HAPPENS IN COCAINE ANONYMOUS?</p>
                                    <p>The essence of C.A. is the spiritual concept of one addict helping another. Sponsorship is a very practical way of achieving this. Sponsors act as mentors or guides, helping sponsees work through the 12 Steps.</p>
                                    <p>We feel that one addict talking to another can provide a level of mutual understanding and fellowship that may be difficult to find through other methods. The fact that an individual has recovered from his or her addiction and is freely passing on this experience is a powerful message for someone desperately seeking a solution to their own addiction. There emerges a bond among us that transcends all other social boundaries. We hold regular meetings to further this Fellowship, allowing new members to find us and, perhaps, the answers they seek.</p>
                                    <p className="is-size-5">WHO CAN ATTEND COCAINE ANONYMOUS?</p>
                                    <p>Anyone with a desire to stop using cocaine and all other mind-altering substances may attend any C.A. meeting, including those designated as closed. Professionals who are not in recovery themselves are welcome to attend our open meetings. Please check our website for further information.</p>
                                    <p className="is-size-5">IS COCAINE ANONYMOUS A RELIGIOUS ORGANIZATION?</p>
                                    <p>C.A. is spiritual but not religious, and our members are free to define their spirituality as they see fit. C.A. encourages each member to cultivate a personal understanding of a Higher Power. Central to our program is an emphasis on what is referred to as a spiritual awakening, emphasizing its practical value, not its philosophical or metaphysical importance.</p>
                                    <p className="is-size-5">IS COCAINE ANONYMOUS A PROFESSIONAL ORGANIZATION?</p>
                                    <p>There is no professional class of help or therapy in C.A. Everything individual members do in service for the fellowship is voluntary.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/a-message-to-the-professionals.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default AMessageToProfessionals
import React from 'react'

const HavingFunInRecovery = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Having Fun in Recovery </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><i>"… we aren’t a glum lot. If newcomers could see no joy or fun in our existence, they wouldn’t want it. We absolutely insist on enjoying life."*</i></p>
                                    <p>One of the most surprising things a newcomer may hear at a C.A. meeting is the sound of laughter. How is it possible that the same people who probably started drinking or using drugs in the pursuit of a good time are now having an even better time without mind-altering substances? At first out of necessity, and then from an honest desire to be open to new experiences, we have allowed those who came before us to teach us how to have fun in recovery. Here are some suggestions you may wish to try:</p>
                                    <p><b>The Meeting After the Meeting</b><br></br>Many groups get together for coffee or a meal after the meeting is over. This is a great way to get to know other members and share a laugh or exchange support. There’s no need to be shy about asking where folks go; remember, everyone else has had the experience of being there for the first time, too.</p>
                                    <p><b>C.A. Activities and Conventions</b><br></br>Dances, campouts, picnics and potlucks are just a few of the activities which may be organized by the local C.A. fellowship. Fellowship events help us learn to enjoy such activities sober in a safe and supportive atmosphere, perhaps for the first time.</p>
                                    <p>Conventions are generally on a larger scale, where members may come from other areas or even other countries to participate. Conventions offer the opportunity to attend workshops and hear speakers we might never have heard before, so that we can experience the C.A. message of recovery in a new way.</p>
                                    <p><b>Service Work</b><br></br>How can something called work be fun? It all depends on who your co-workers are. Getting involved in service, whether at your home group or perhaps on a committee planning a C.A. activity, can lead to new friendships with others who are seriously committed to their recovery. In the process, we have the opportunity to learn about service structure, the 12 Traditions, and everything that happens behind the scenes to keep C.A. going strong.</p>
                                    <p><b>Willingness and Open-Mindedness</b><br></br>We may still occasionally find ourselves asking, “What am I going to do now that I’m sober?” At these times we can ask a trusted servant, such as the group’s Secretary or GSR, or look on the internet at www.ca.org for information on C.A. activities and events. We can also ask our sponsors, friends, or fellow home group members for suggestions on new, fun things to do.</p>
                                    <p>Each time we make the effort to have open minds, try something new and end up enjoying ourselves, we add to our recovery foundation. As we grow more spiritually fit, we may find we can participate in activities we chose to forego when we first got sober. Before long, it becomes easy to have fun in recovery. Instead of feeling like we are missing out on having a good time, we become grateful for the new opportunities we have to celebrate being happy, joyous and free.</p>
                                    <p><i>*This excerpt from Alcoholics Anonymous, page 132, is reprinted with permission of Alcoholics Anonymous World Services, Inc. (“AAWS”). Permission to reprint this excerpt does not mean that AAWS has reviewed or approved the contents of this publication, or that AAWS necessarily agrees with the views expressed herein. A.A. is a program of recovery from alcoholism only—use of this excerpt in connection with programs and activities which are patterned after A.A., but which address other problems, or in any other non-A.A. context, does not imply otherwise.</i></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Having-Fun-in-Recovery.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default HavingFunInRecovery
import React from 'react'

const AndAllOtherMindAlteringSubstances = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > ...And All Other Mind Altering Substances </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>STEP ONE:</b> We admitted we were powerless over cocaine and all other mind-altering substances—that our lives had become unmanageable.</p>

                                    <p><b>TRADITION THREE:</b> The only requirement for C.A. membership is a desire to stop using cocaine and all other mind-altering substances.</p>

                                    <p>Many people come to Cocaine Anonymous thinking one of two things: “I rarely (or never) even used cocaine. I don’t think I belong here,” or “What exactly does the ‘and all other mind-altering substances’ part mean? I came to Cocaine Anonymous because cocaine had become a problem in my life.”</p>

                                    <p>Those of us who have been C.A. members for a while have heard questions and statements like this before; perhaps the words were even our own. Over time, virtually every single one of us has realized that our real problem is not cocaine or any specific drug, it is the disease of addiction.</p>

                                    <p>Some of us never even used cocaine. Some of us used a variety of drugs, and for others it was combining cocaine with alcohol and/or other drugs that got us into trouble and made our lives miserable. Many of us rode drug roller coasters; there were drugs to come down with, drugs to go up with, and drugs to mellow out with.</p>

                                    <p>During those times we attempted to regain control we found that any substitution or new combination inevitably created the same result. It was hard to stop and easy to get started. Experience after experience revealed that substitution was no cure. If our bodies were not absolutely free of drugs and alcohol, the obsession to use more of something was always lurking.</p>

                                    <p>For example, imagine that you have just run out of one drug and cannot get any more. What would you use for a substitute? Alcohol for heroin, methamphetamine for cocaine, prescriptions for whatever, vice-versa—the list could go on and on and it really wouldn’t matter. The point is that addicts like us soon find ourselves unable to stop using the substitute. Whatever drug we use, the problem of not being able to stop resurfaces, often bigger than before.</p>

                                    <p>At some point we finally realize that we cannot control our use of any mind-altering substances. The problem isn’t the drug of choice; the problem is the disease of addiction. With its Third Tradition and all-inclusive First Step, Cocaine Anonymous welcomes anyone with a drug or alcohol problem and offers a solution.</p>

                                    <p><b>ALCOHOL AND MARIJUANA</b><br></br>
                        Marijuana is a potent drug, and alcohol is a mind-altering chemical in liquid form. Many people don’t realize that these are no different from any other drugs regarding the potential to get us into trouble. One drink is never enough; just as one puff, hit, fix, bump, pill or snort is never enough. We are masters at combining and substituting one drug with another to get high.</p>

                                    <p>Has getting drunk or loaded ever been the unintentional result of “just” one drink or “just” a beer? Have you ever experienced the consequences of impaired judgment or decision-making ability as a direct result of smoking “just” a little pot? Did one of anything ever lead to two or three or more of something harder or stronger?</p>

                                    <p>Many of us never thought that alcohol and/or marijuana were part of our problem, but upon honest examination and working the Steps, we usually find a need to re-evaluate that notion.</p>

                                    <p><b>PRESCRIPTION DRUGS</b><br></br>
                        The use of prescription drugs may be medically necessary at times. However, old patterns of thinking influenced by the presence of a mind-altering substance in our system can soon have us convinced that we need to take it more frequently than prescribed. An informed prescribing physician, placing our medication in control of someone we can trust, and honest communication with a sponsor or another recovering addict can be helpful in preventing abuse.</p>

                                    <p>Our bodies and minds don’t know the difference between drugs used for legitimate reasons and drugs used for recreational pleasure. It’s a sound practice to enlist the support of all of our physicians and/or mental health care professionals in continuing on the path to recovery. Abruptly stopping the use of tranquilizers, antidepressants or other prescription drugs can be dangerous and even deadly, and should only be done under the guidance of an informed physician.</p>

                                    <p><b>OVER-THE-COUNTER AND COMMONLY USED LEGAL DRUGS</b><br></br>
                        Over-the-counter and other legal drugs (such as cough syrups or pain relievers that contain alcohol and/or codeine, diet pills that act as stimulants, and antihistamines that act as depressants) can be just as big of a problem for us as street drugs. We suggest that you become a label reader. There are many products on the market that can be dangerous to an addict who has the potential to abuse just about any mind-altering chemical.</p>

                                    <p>Uninformed addicts can be a hazard to themselves and others. We encourage you to ask your doctor or pharmacist if you have any questions regarding medications. Be honest with your sponsor about what drugs you take or are prescribed to you.</p>

                                    <p>Remember, we’re powerless over cocaine and all other mind-altering substances. Step One is a beginning, but be sure to move forward. A spiritual awakening as the result of working all Twelve Steps is the solution to the problem of addiction.</p>

                                    <p><b>SO WHAT DOES “… AND ALL OTHER MIND-ALTERING SUBSTANCES” MEAN?</b><br></br>
                        It means that it is the collective experience of the members of Cocaine Anonymous that addiction is a problem not limited to any one substance. It means that C.A.’s Twelve Steps are not drug-specific, and that Cocaine Anonymous is not a drug-specific Fellowship. It means that it doesn’t matter to us if you drank or what type of drugs you used; if you have a desire to stop, you are welcome here!</p>

                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/all-mind-altering-subs.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default AndAllOtherMindAlteringSubstances
import React from 'react'

const CAPreamble = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > CA Preamble </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>
                                    Cocaine Anonymous is a fellowship of men and women who share their experience,
                                    strength and hope with each other that they may solve their common problem and help
                                    others to recover from their addiction. The only requirement for membership is a desire
                                    to stop using cocaine and all other mind‐ altering substances. There are no dues or
                                    fees for membership; we are fully self‐ supporting through our own contributions. We
                                    are not allied with any sect, denomination, politics, organization or institution. We do
                                    not wish to engage in any controversy, and we neither endorse nor oppose any
                                    causes. Our primary purpose is to stay free from cocaine and all other mind‐ altering
                                    substances and to help others achieve the same freedom.</p>
                                    <p>
                                    We use the Twelve‐ Step recovery Program because it has already been proven that
                                    the Twelve‐ Step Recovery Program works.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                &nbsp;
                            </div>
                        </article>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default CAPreamble
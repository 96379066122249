import React from 'react'

const ReachingOutToTheDeafAndHardOfHearing = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Reaching out to the Deaf and Hard of Hearing </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>In the ancient world, when children were discovered to be deaf, they were often disowned and left to die or fend for themselves.</p>
                                    <p>Currently, six to ten percent of the United States' general population are deaf or hard of hearing. Of that number, ten percent are addicts and/or alcoholics (approximately 2 million).<br></br>Without access to a program of recovery, this very large group of people are again, left to die or fend for themselves.<br></br>Cocaine Anonymous' preamble states that "our primary purpose is to stay free from cocaine and all other mind-altering substances, and to help others achieve the same freedom." C.A.'s 12th Step tells us that we, as a Fellowship, have a responsibility to reach out to carry this message to addicts. The 1st and 5th Traditions remind us that "our common welfare comes first, that personal recovery DEPENDS upon C.A. unity" and that "each group has but one primary purpose — to carry its message to the addicts who still suffers."</p>
                                    <p>So just how does one reach out to the deaf or hard of hearing?</p>
                                    <p>There are many ways! Like everything else, reaching out to the deaf or hard of hearing addict requires willingness and open-mindedness. In this pamphlet, we present some ways to help you get started.</p>
                                    <p><b>A. Bring the message of C.A. recovery to the hard of hearing</b></p>
                                    <p>Start a meeting at a local school for the Deaf. These schools can be located in your local phone book and/or by contacting your State Commission on Deaf and Hard of Hearing.</p>
                                    <p>
                                        <ul>
                                            <li>Bring literature and leave extra copies behind.</li>
                                            <li>Welcome those present with hugs or handshakes.</li>
                                            <li>Be willing to stay after the meeting and talk with those present (with the help of an interpreter).</li>
                                            <li>Bring writing pads and pens to aid communication.</li>
                                            <li> Invite members and interpreters out to coffee, if school rules allow. Remember! We are guests!</li>
                                            <li>Their rules must be respected.</li>
                                        </ul>
                                    </p>
                                    <p><b>B. Establish at least one meeting per month at which an interpreter is present</b></p>
                                    <p>
                                        <ul>
                                            <li> Include this information in meeting schedules.</li>
                                            <li>Advise Helpline(s), local newspapers, concerned agencies and schools, and clergy of meeting.</li>
                                            <li>Confirm interpreter one week prior to meeting.</li>
                                            <li>Have special seating reserved in front of the room.</li>
                                            <li>Welcome newcomers with hugs or handshakes.</li>
                                            <li>Speak slowly to allow for lip-reading.</li>
                                            <li>Ask newcomers and interpreters out to coffee.</li>
                                            <li>Ensure meeting budget can afford interpreter (approximately $50-$75) and include this in Prudent Reserve.</li>
                                            <li>If transportation is a problem, have members of the meeting volunteer to pick up and drive new members home.</li>
                                        </ul>
                                    </p>
                                    <p><b>C. Events/Conventions</b></p>
                                    <p>
                                        <ul>
                                            <li>Arrange for an interpreter to be present well in advance. Confirm the date with him/her one week prior to event/convention.</li>
                                            <li>Distribute flyers announcing the event/convention to local schools, agencies and social clubs for the deaf.</li>
                                            <li>Arrange reserved seating in the front row.</li>
                                            <li>Have all C.A. literature available.</li>
                                            <li>Remember our 1st Tradition!</li>
                                        </ul>
                                    </p>
                                    <p><b>D. General Ways to Reach Out and Touch Deaf Addicts</b></p>
                                    <p>
                                        <ul>
                                            <li>Send all current C.A. literature to schools for the deaf, social agencies that help this community</li>
                                            <li>Public Information and Hospitals &amp; Institutions Committees), and treatment centers in your area.</li>
                                            <li>Establish contact with local interpreters (via State Commission on Deaf and Hard of Hearing). Answer any questions they may have on recovery and C. A. Invite interpreters to open meetings (advise them of our anonymity statements).</li>
                                            <li>Be WILLING to reach out your hand in C.A. service and/or on 12th Step calls to deaf/hard of hearing addicts still suffering. (Remember, never go on a 12th Step call alone!)</li>
                                            <li>Find out if your state has a telephone relay service (this is often a free service) to enable communication — computer modem, fax, TDY.</li>
                                        </ul>
                                    </p>
                                    <p><b>IDENTIFY … DON’T COMPARE!</b></p>
                                    <p><b>Share your EXPERIENCE, STRENGTH &amp; HOPE.</b><br></br>That’s why we all come to Cocaine Anonymous.</p>
                                    <p><b>Communicate the message of Faith, Hope and Courage.</b></p>
                                    <img src="/img/literature/deaf-hope-faith-courage.jpg" alt=""></img>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/reaching-the-deaf.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ReachingOutToTheDeafAndHardOfHearing
import React from 'react'

const AHigherPower = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > A Higher Power </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>As a newcomer, you may have thought or said, “What’s this talk about God? I came here to stop using cocaine, not to join a new religion.” Don’t feel alone. Many of us were put off with the talk about God when we first came to meetings.</p>
                                    <p>It is easy enough to confuse the word spirituality with religion. As it relates to God, Cocaine Anonymous is a spiritual program, not a religious one. In C.A., we believe each individual can choose a Higher Power of his or her own. In short, a God of his or her own understanding.</p>
                                    <p>If you are like many of us, you came to C.A. without a conscious belief in a Higher Power. Or, perhaps you chose to avoid a Higher Power because you were taught about a punishing God. It doesn’t matter. All that is necessary to start is that you are open-minded to the idea that some Power greater than yourself may be able to restore you to sanity.</p>
                                    <p>The first step in solving any problem is recognizing it. The same holds true in solving a problem with cocaine.</p>
                                    <p>The second step in solving a problem is believing that there might be a solution. The fact that you’ve come to a C.A. meeting shows that you believe that there is a Power of some kind, greater than yourself, that can help you get your life back in order. You have proven, just by showing up, that you believe that there must be some information, somewhere, you can use to get rid of your obsessions with cocaine, drugs, and/or alcohol. You have already started!</p>
                                    <p>The third step in solving a problem, after having found evidence of a solution, is putting faith in that solution and trying it. The solution for us meant admitting that our management of the problem wasn’t working. Cocaine Anonymous introduced us to a Power greater than ourselves that could manage our problem. That doesn’t necessarily mean we have to turn our will and our lives over to the care of the God that we heard of in the past. It can mean trusting in a Power of our own understanding. This is the beginning of our Higher Power, God as we understand Him.</p>
                                    <p>Some of us adopt, or come back to, a traditional God. Others see our Higher Power as some kind of force. Some define it as the force of the group, while others don’t define it at all.</p>
                                    <p>At first, it is sufficient for God, as you understand Him, to be the power that the group obviously has to help get rid of the obsession to use.</p>
                                    <p>No one comes into Cocaine Anonymous to find God. We come to these rooms to get rid of a terrifying drug habit. Look around in a meeting. You are surrounded by people who came as a last resort. We came into these rooms emotionally, financially, and spiritually bankrupt. We have experienced all sorts of tragedies as a result of cocaine, drugs, and/or alcohol. We have lived many of the same horrors you have, yet today we are happy. We are free from the misery, terror, and pain of drug addiction.</p>
                                    <p>As long as you are willing, your belief will grow. You will learn through your own experience and the experiences of others how a Higher Power can help you with your cocaine problem.</p>
                                    <p>Maybe some of us were worse off than you; maybe some of us didn’t hit as low a bottom as you. Still, the fact remains, that those of us who are recovering have come to believe that the power of the group or of a Higher Power of our own understanding can restore us to sanity.</p>
                                    <p>After you are around the program for a few weeks and months, you will begin to see changes in your thinking. You will begin to feel better. You will see changes in the other newcomers that came in with you. We call those changes miracles. If you are having trouble with the talk about God, remember:</p>
                                    <p>
                                        <ul>
                                            <li>Be open-minded.</li>
                                            <li>C.A. is a spiritual program, not a religious one.</li>
                                            <li>All you have to do is to be willing to believe.</li>
                                            <li>Your Higher Power can be the group as a whole.</li>
                                            <li>You start with belief, your experience will come.</li>
                                        </ul>
                                    </p>
                                    <p><b>Don’t leave before the miracle happens!</b>
                                    </p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/A-Higher-Power.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default AHigherPower
import React from 'react';
import { Link } from 'react-router-dom';
import "./Header.css";

class Header extends React.Component {
  constructor(props){
    super(props);
    this.state = {
     selected: false 
    }
  }
  _toggleNav = (e) => {
    e.preventDefault();
    this.setState({
      selected: !this.state.selected
    })
  }
  _toggleNav2 = (e) => {
    this.setState({
      selected: false
    })
  }

  render() {
    return (
          <div className="container">
            <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
          <div className="navbar-item">
            <Link onClick={(e) => this._toggleNav2(e)} to="/" className="navbar-item"><img src="/img/ca-logo.png" alt="CA" /></Link>
          </div>
          <a href="0" onClick={(e) => this._toggleNav(e)} role="button" className={this.state.selected === false ? 'navbar-burger burger' : 'is-active navbar-burger burger'} aria-label="menu" aria-expanded="false" data-target="navbarMain">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
          </div>
          <div id="navbarMain" className={this.state.selected === false ? 'navbar-menu' : 'is-active navbar-menu'}>
            <div className="navbar-start">
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/">Home</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/meetings">Meetings</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/donate">Donate</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/events">Events</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/hi">H&amp;I</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/pi">Public Information</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/literature">Literature</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/about">About</Link>
              <Link onClick={(e) => this._toggleNav2(e)} className="navbar-item" to="/contact">Contact</Link>
            </div>

            <div className="navbar-end">
              <div clas="navbar-item">
                <div className="buttons">
                  <a href="tel:212-262-2463" className="ca-bg button is-primary">
                    <strong>Helpline</strong>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </nav>
          </div>
    )
  }
}

export default Header;
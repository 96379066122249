import React from 'react'

const Crack = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Crack </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><i>“At first it was fun. Then it reached the point where every time I used, I had chest pains and my heartbeat would go crazy; and yet I couldn’t stop.”</i></p>
                                    <p><i>“There I lay in the hospital, almost dead from an overdose. My heart felt like it was about to jump out of my chest and yet all I could think about was getting another hit.”</i></p>
                                    <p><i>“I couldn’t stop until my whole paycheck was gone. I promised myself that next week it would be different—‘I’ll only do one’—but one turned into two, two turned into three, and before I knew it, I was promising myself again: ‘Next week it will be different … ’”</i></p>
                                    <p><i>“I had it all—a good job, a loving wife and family, everything I ever wanted and WHAM! It was all gone in a matter of weeks.”</i></p>
                                    <p><i>“I said that I would never be caught peeping out windows or crawling around on the floor. A few days later I was peeping and geeking. I thought someone was out to get me. The paranoia almost drove me insane.”</i></p>
                                    <p><i>“I knew it was killing the baby growing inside me, but I couldn’t stop. I couldn’t stop and then it was too late and I still couldn’t stop.”</i></p>
                                    <p><i>“At first it was gotta quit; then it was gotta quit, can’t quit; can’t quit, gonna die.”</i></p>
                                    <p>Can you relate to these feelings? Have you been through many of the same situations, made the same promises to your family, to your friends, and most of all, to yourself? So have we. We have truly wanted to keep these promises. We did everything in our power, but we still always seemed to come up short.</p>
                                    <p>Who are we? We are former crack users. We are members of Cocaine Anonymous.</p>
                                    <p>Today, many of us realize that crack cocaine played a major role in our failures and in our broken promises. We know that cocaine took away our drive to do anything except more cocaine. We were driven by cocaine and nothing else seemed to matter. Even if it made us feel miserable, we had to have it.</p>
                                    <p>If crack cocaine made us feel miserable, why did so many of us continue to use? Ask a hundred cocaine addicts that same question and you will probably get a hundred different answers, but no matter how many reasons exist, one fact is certain: crack cocaine is a highly addictive drug. Once used, it becomes the root of most of our problems.</p>
                                    <p>For many of us, it was only a matter of weeks of using before our whole world came crashing down upon us; for some it came sooner, for some it came later. Some of us used other drugs or alcohol for years without ever experiencing the loss that we experienced with crack cocaine.</p>
                                    <p><b>So how do we stop using crack?</b><br></br>Cocaine Anonymous has a few simple suggestions on how you should start:</p>
                                    <p><b>Abstinence</b><br></br>Don’t use no matter what. We suggest abstinence from all mind-altering substances.</p>
                                    <p><b>One Day at a Time</b><br></br>Remember that you don’t have to stay clean and sober for the rest of your life. We do this one day at a time.</p>
                                    <p><b>Meetings</b><br></br>Attend C.A. meetings daily. Cocaine Anonymous meetings are where you meet other recovering addicts who have similar problems. We share our experience, strength, and hope with each other to find common solutions through working the Twelve Steps of Cocaine Anonymous.</p>
                                    <p><b>Telephone</b><br></br>When you want to use, call the Cocaine Anonymous hotline in your area. Get phone numbers from people in the meetings and call them day or night.<br></br>Remember that you are not alone. Keep in touch with other recovering cocaine addicts. We are here to help you.</p>
                                    <p><b>We’re Here and We’re Free&trade;</b></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Crack.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Crack
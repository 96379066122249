import React from 'react'

const TipsForStayingCleanSober = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Tips for Staying Clean &amp; Sober </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>Here are some tips to help you:
                                        <ul>
                                            <li>Admit that you lack the power to stay clean on your own.</li>
                                            <li>Make sobriety your #1 priority.</li>
                                            <li>Throw away all your drugs and drug paraphernalia.</li>
                                            <li>Don’t deal drugs.</li>
                                            <li>Go to meetings daily.</li>
                                            <li>Get phone numbers and use them.</li>
                                            <li>If your dealer calls, hang up and call someone on your phone list.</li>
                                            <li>If no one is home and you’re in trouble, call the C.A. helpline.</li>
                                            <li>Stay away from people, places and things with which you associate drug use.</li>
                                            <li>Choose a home group; let people get to know you.</li>
                                            <li>Get a sponsor and call your sponsor every day.</li>
                                            <li>Work the Twelve Steps of Cocaine Anonymous with your sponsor.</li>
                                            <li>Read the books and literature.</li>
                                            <li>Be of service; get a commitment at a meeting.</li>
                                            <li>Be honest, open-minded and willing (H.O.W.).</li>
                                            <li>Don’t get too hungry, angry, lonely or tired (H.A.L.T.).</li>
                                            <li>Keep it simple. We stay clean and sober one day at a time.</li>
                                            <li>Pray. Ask questions. Seek answers.</li>
                                            <li>No matter what happens, keep coming back!</li>
                                            <li>Be patient. Recovery takes time.</li>
                                            <li>Remember, we care.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Tips-for-Staying-Clean.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default TipsForStayingCleanSober
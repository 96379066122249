import React from 'react'

const WhoIsACocaineAddict = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Who Is A Cocaine Addict? </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                        Some of us can answer without hesitation, “I am!” Others aren’t so sure. Cocaine
                        Anonymous believes that no one can decide for another whether he or she is addicted.
                        One thing is sure, though: every single one of us has <i>denied</i> being an addict. For
                        months, for years, we who now freely admit that we are cocaine addicts thought that we
                        could control cocaine when in fact it was controlling us.</p>
                        <p>
                        <ul style={{listStyleType: "none"}}>
                            <li><i>“I only use on weekends,”</i> or</li>
                            <li><i>“It hardly ever interferes with work,”</i> or</li>
                            <li><i>“I can quit, it’s only psychologically addicting, right?”</i> or</li>
                            <li><i>“I only snort, I don’t base or shoot,”</i> or</li>
                            <li><i>“It’s this relationship that’s messing me up.”</i></li>
                        </ul>
                        </p>
                        <p>
                            Many of us are still perplexed to realize how long we went on, never getting the same
                            high we got at the beginning, yet still insisting, and believing—so distorted was our
                            reality—that we were getting from cocaine what actually always eluded us.
                        </p>
                        <p>
                            We went to any lengths to get away from being just ourselves. The lines got fatter; the
                            grams went faster; the week’s stash was all used up today. We found ourselves
                            scraping envelopes and baggies with razor blades, scratching the last flakes from the
                            corners of brown bottles, snorting or smoking any white speck from the floor when we
                            ran out. We, who prided ourselves on our fine-tuned state of mind! Nothing mattered
                            more to us than the straw, the pipe, the needle. Even if it made us feel miserable, we
                            had to have it.
                        </p>
                        <p>
                            Some of us mixed cocaine with alcohol or other drugs, and found temporary relief in the
                            change, but in the end, it only compounded our problems. We tried quitting by
                            ourselves, finally, and managed to do so for periods of time. After a month, we imagined
                            we were in control. We thought our system was cleaned out and we could get the old
                            high again, using half as much. This time, we’d be careful not to go overboard. But we
                            only found ourselves back where we were before, and worse.
                        </p>
                        <p>
                            We never left the house without using first. We didn’t make love without using. We didn’t
                            talk on the phone without coke. We couldn’t fall asleep; sometimes it seemed we
                            couldn’t even breathe without cocaine. We tried changing jobs, apartments, cities,
                            lovers—believing that our lives were being screwed up by circumstances, places,
                            people. Perhaps we saw a cocaine friend die of respiratory arrest, and <i>still</i> we went on
                            using! But eventually we had to face facts. We had to admit that cocaine was a serious
                            problem in our lives, that we <i>were</i> addicts.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default WhoIsACocaineAddict
import React from 'react';
import Modal from '../Modal/Modal';
import './Meetings.css'
class Meetings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modalType: '',
      modalContent: '',
      modalShow: false,
    };
  }


  _setModal = (img, type ,e) => {
    e.preventDefault();
    this.setState({ 
        modalType: type,
        modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props)=> {
      const newState = !prev.modalShow;
      return { modalShow : newState}
    });
  }

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  }

  render() {
    return (
      <React.Fragment>
      <section className="section">
        <div className="container">
          <figure className="image is-16by9">
            <iframe className="has-ratio" title='tsml' src="./meetings.html" width="100%" height="auto"></iframe>
          </figure>
        </div>
      </section>
      <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} openLink={this._openLink} modalState={this.state.modalShow} />
      </React.Fragment>
    );
  }
}


export default Meetings;
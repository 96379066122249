import React from "react";
import groupBy from "lodash/groupBy";
import moment from "moment";
// import meetingdata from "./meetings-data";

import "./Print.css";

class Print extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetings: []
    };
  }

  componentDidMount() {
    //window.print();
    // window.onfocus=function(){ window.close();}
  }

  _groupedMeetings = region => {
    let groupedMeetings = [];
    groupedMeetings = groupBy(this.state.meetings, "Region");

    //array of meetings in this region
    groupedMeetings = groupedMeetings[region];

    //group by location
    groupedMeetings = groupBy(groupedMeetings, "Location");

    // debugger;
    return groupedMeetings;
  };

  _splitType = types => {
    let _type = '';
    for(let i=0; i < types.length; i++){
      if(i < (types.length -1))
        _type += types[i] + " / ";
      else
        _type += types[i];
    }
    return _type;
  }

  render() {
    return (
      <div>
        <div className="columns">
          <div className="column is-half">
            <br></br>
            <div className="columns">
              <div className="column is-half">
                NEED HELP RIGHT NOW?<br></br>
                CALL OUR HELPLINE (212) COCAINE<br></br>
                <br></br>
                ©{moment().format('YYYY')} CANY, Inc. http://canewyork.org
              </div>
              <div className="column is-half">
                Telephone Numbers:<br></br><br></br>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
                <div className="divider">&nbsp;</div>
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="center">
              <img src="/img/ca-logo.png" alt="CA" /><br></br>
              <strong>
              Meeting List for Cocaine Anonymous of the Greater New York Area
            </strong>
            </div>
            <p className="size10">
              Cocaine Anonymous is a fellowship of men and women who share their
              experience, strength and hope with each other that they may solve
              their common problem and help others to recover from their
              addiction. The only requirement for membership is a desire to stop
              using cocaine and all other mind-altering substances. There are no
              dues or fees for membership; we are fully self-supporting through
              our own contributions. We are not allied with any sect,
              denomination, politics, organization, or institution. We do not
              wish to engage in any controversy and we neither endorse nor
              oppose any causes. Our primary purpose is to stay free from
              cocaine and all other mind-altering substances, and to help others
              achieve the same freedom. We use the Twelve Step Recovery Program;
              because it has already been proven that the Twelve Step Recovery
              Program works
            </p>
            <div className="columns steps">
              <div className="column is-half bordered">
                Twelve Steps of Cocaine Anonymous
                <ol>
                  <li>
                    We admitted we were powerless over cocaine and all other
                    mind-altering substances — that our lives had become
                    unmanageable.
                  </li>
                  <li>
                    Came to believe that a Power greater than ourselves could
                    restore us to sanity.
                  </li>
                  <li>
                    Made a decision to turn our will and our lives over to the
                    care of God as we understood Him.
                  </li>
                  <li>
                    Made a searching and fearless moral inventory of ourselves.
                  </li>
                  <li>
                    Admitted to God, to ourselves, and to another human being
                    the exact nature of our wrongs.
                  </li>
                  <li>
                    Were entirely ready to have God remove all these defects of
                    character.
                  </li>
                  <li>Humbly asked Him to remove our shortcomings. </li>
                  <li>
                    Made a list of all persons we had harmed, and became willing
                    to make amends to them all.
                  </li>
                  <li>
                    Made direct amends to such people wherever possible, except
                    when to do so would injure them or others.
                  </li>
                  <li>
                    Continued to take personal inventory and when we were wrong
                    promptly admitted it.
                  </li>
                  <li>
                    Sought through prayer and meditation to improve our
                    conscious contact with God as we understood Him, praying
                    only for knowledge of His will for us and the power to carry
                    that out.
                  </li>
                  <li>
                    Having had a spiritual awakening as the result of these
                    steps, we tried to carry this message to addicts, and to
                    practice these principles in all our affairs.
                  </li>
                </ol>
              </div>
              <div className="column is-half bordered">
                Twelve Traditions of Cocaine Anonymous
                <ol>
                  <li>
                    Our common welfare should come first; personal recovery
                    depends upon C.A. unity.
                  </li>
                  <li>
                    For our group purpose there is but one ultimate authority —
                    a loving God as He may express Himself in our group
                    conscience. Our leaders are but trusted servants; they do
                    not govern.
                  </li>
                  <li>
                    The only requirement for C.A. membership is a desire to stop
                    using cocaine and all other mind-altering substances.
                  </li>
                  <li>
                    Each group should be autonomous except in matters affecting
                    other groups or C.A. as a whole.
                  </li>
                  <li>
                    Each group has but one primary purpose — to carry its
                    message to the addict who still suffers.
                  </li>
                  <li>
                    A C.A. group ought never endorse, finance, or lend the C.A.
                    name to any related facility or outside enterprise, lest
                    problems of money, property and prestige divert us from our
                    primary purpose.
                  </li>
                  <li>
                    Every C.A. group ought to be fully self-supporting,
                    declining outside contributions.
                  </li>
                  <li>
                    Cocaine Anonymous should remain forever nonprofessional, but
                    our service centers may employ special workers.
                  </li>
                  <li>
                    C.A., as such, ought never be organized; but we may create
                    service boards or committees directly responsible to those
                    they serve.
                  </li>
                  <li>
                    Cocaine Anonymous has no opinion on outside issues; hence
                    the C.A. name ought never be drawn into public controversy.
                  </li>
                  <li>
                    Our public relations policy is based on attraction rather
                    than promotion; we need always maintain personal anonymity
                    at the level of press, radio, television and films.
                  </li>
                  <li>
                    Anonymity is the spiritual foundation of all our Traditions,
                    ever reminding us to place principles before personalities.
                  </li>
                </ol>
              </div>
            </div>
            <p className="size10">
              The Twelve Steps and the Twelve Traditions are reprinted and
              adapted with permission of Alcoholics Anonymous World Services,
              Inc. Permission to reprint and adapt the Twelve Steps of
              Alcoholics Anonymous does not mean that A.A. is affiliated with
              this program. A.A. is a program of recovery from alcoholism. Use
              of the Steps in connection with programs and activities which are
              patterned after A.A. but which address other problems does not
              imply otherwise. Approved Literature. Cocaine Anonymous World
              Services, Inc. Copyright {moment().format('YYYY')}
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <table>
              <thead>
                <tr>
                  <th>MANHATTAN</th>
                  <th>SUN</th>
                  <th>MON</th>
                  <th>TUE</th>
                  <th>WED</th>
                  <th>THU</th>
                  <th>FRI</th>
                  <th>SAT</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this._groupedMeetings("Manhattan")).map(
                  (location, i) => (
                    <tr key={i}>
                      <th>
                        {location}<br></br>
                        {this._groupedMeetings("Manhattan")[location][0].Address}<br></br>
                        {this._groupedMeetings("Manhattan")[location][0].Address2}
                      </th>
                      <td>
                        {this._groupedMeetings("Manhattan")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Sunday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}                              
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Manhattan")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Monday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Manhattan")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Tuesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Manhattan")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Wednesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Manhattan")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Thursday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Manhattan")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Friday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Manhattan")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Saturday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <br></br>
            <table>
              <thead>
                <tr>
                  <th>QUEENS</th>
                  <th>SUN</th>
                  <th>MON</th>
                  <th>TUE</th>
                  <th>WED</th>
                  <th>THU</th>
                  <th>FRI</th>
                  <th>SAT</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this._groupedMeetings("Queens")).map(
                  (location, i) => (
                    <tr key={i}>
                      <th>
                        {location}<br></br>
                        {this._groupedMeetings("Queens")[location][0].Address}<br></br>
                        {this._groupedMeetings("Queens")[location][0].Address2}
                      </th>
                      <td>
                        {this._groupedMeetings("Queens")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Sunday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Queens")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Monday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Queens")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Tuesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Queens")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Wednesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Queens")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Thursday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Queens")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Friday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Queens")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Saturday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="column is-half">
            <br></br>
            <table>
              <thead>
                <tr>
                  <th>BROOKLYN</th>
                  <th>SUN</th>
                  <th>MON</th>
                  <th>TUE</th>
                  <th>WED</th>
                  <th>THU</th>
                  <th>FRI</th>
                  <th>SAT</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this._groupedMeetings("Brooklyn")).map(
                  (location, i) => (
                    <tr key={i}>
                      <th>
                        {location}<br></br>
                        {this._groupedMeetings("Brooklyn")[location][0].Address}<br></br>
                        {this._groupedMeetings("Brooklyn")[location][0].Address2}
                      </th>
                      <td>
                        {this._groupedMeetings("Brooklyn")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Sunday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Brooklyn")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Monday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Brooklyn")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Tuesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Brooklyn")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Wednesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Brooklyn")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Thursday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Brooklyn")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Friday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Brooklyn")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Saturday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <br></br>
            <table>
              <thead>
                <tr>
                  <th>LONG ISLAND</th>
                  <th>SUN</th>
                  <th>MON</th>
                  <th>TUE</th>
                  <th>WED</th>
                  <th>THU</th>
                  <th>FRI</th>
                  <th>SAT</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this._groupedMeetings("Long Island")).map(
                  (location, i) => (
                    <tr key={i}>
                      <th>
                        {location}<br></br>
                        {this._groupedMeetings("Long Island")[location][0].Address}<br></br>
                        {this._groupedMeetings("Long Island")[location][0].City}
                      </th>
                      <td>
                        {this._groupedMeetings("Long Island")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Sunday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Long Island")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Monday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Long Island")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Tuesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Long Island")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Wednesday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Long Island")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Thursday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Long Island")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Friday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                      <td>
                        {this._groupedMeetings("Long Island")[location].map(
                          (meeting, i) =>
                            meeting.Day === "Saturday" && (
                              <React.Fragment key={i}>
                                {this._splitType(meeting.Type)}
                                <br></br>
                                {meeting.Time}
                                <br></br>
                                {meeting.Meeting}
                              </React.Fragment>
                            )
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <br></br>
            <p>
              B-Beginners, BB-Big Book, CD-Closed Discussion, M-Men Only,
              OD-Open Discussion [public invited], S-Step Meeting, T-Topic,
              W-Women Only, CP-Candlelight Promises, G&amp;L-Gay and Lesbian,
              Anniversary Meetings celebrate milestones of members (all are
              welcome)
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Print;

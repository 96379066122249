import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import CookieConsent from "react-cookie-consent";
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="level">
      <div className="level-item">
        &copy; {moment().format('YYYY')} CANY, Inc. All rights reserved
      </div>
      <div className="level-item">
        244 Madison Avenue #1530 New York, NY 10016
      </div>
      <div className="level-item">
        Helpline &nbsp; <a href="tel:212-262-2463">(212) 262-2463 </a>
      </div>
    </div>
    <div className="level">
      <div className="level-item">
      <Link to="/copyright">Copyright &amp; Usage</Link>
      </div>
      <div className="level-item">
      <a href="files/CANY_Bylaws_2011_FINAL.pdf" target="_blank">Cocaine Anonymous Bylaws</a>
      </div>
      <div className="level-item">
      <Link to="/privacy">Privacy &amp; Security Policy</Link>
      </div>
      <div className="level-item">
        Contact &nbsp;<a href="mailto:canewyorkwebsite@gmail.com">Webservant</a>
      </div>
    </div>
    <CookieConsent><div style={{textAlign: "center"}}>This website uses cookies to enhance the user experience.</div></CookieConsent>
  </footer >
);

export default Footer;
import { useEffect } from "react";
import { withRouter } from "./withRouter";

function ScrollToTop() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (null);
}

export default withRouter(ScrollToTop);
import React from 'react';
import Modal from '../Modal/Modal';
import { Link } from "react-router-dom";

class Donate extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modalType: '',
      modalContent: '',
      modalShow: false
    }
  }

  _setModal = (img, type ,e) => {
    e.preventDefault();
    this.setState({ 
        modalType: type,
        modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props)=> {
      const newState = !prev.modalShow;
      return { modalShow : newState}
    });
  }

  render(){
    return (
      <React.Fragment>
      <section className="section">
          <div className = "container" >
            <h1 className = "title" > Donate </h1>
              <div className="columns">
                <div className="column is-8">
                  <div className="box has-background-white">
                    <p>You can donate to CA New York with a credit card or via PayPal. The money you donate will help support the fellowship.</p>
                    <br></br>
                    <a className="button" href="https://canewyork-store.square.site/" target="_blank" rel="noopener noreferrer">
                      <span className="icon">
                        <i className="fas fa-dollar-sign"></i>
                      </span>
                      <span>Click here to donate via Credit Card</span>
                  </a>
                  <br></br>
                  <br></br>
                    <a className="button" href="https://paypal.me/canewyork" target="_blank" rel="noopener noreferrer">
                      <span className="icon">
                        <i className="fas fa-dollar-sign"></i>
                      </span>
                      <span>Click here to donate via PayPal</span>
                  </a>
                  <p><br></br></p>
                  <p>Where does my money go? <br></br><br></br>From the <Link to="/literature/the_7th_tradition">7th Tradition</Link>:<br></br>
                  <i>What do the Districts/Areas do with this money? Districts and Areas exist to serve the Groups. Each District/Area also has expenses that can include a hotline’s telephone bill or answering service, maintaining a website and publishing meeting schedules. It typically also purchases chips and literature, keeping enough in stock for the Groups it serves. Districts and Areas should also keep prudent reserves.</i></p>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
          </div >   
        </section>
        <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} modalState={this.state.modalShow} />
        </React.Fragment>
    )
  }
}

export default Donate;
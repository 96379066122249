import React from 'react'

const TheHomeGroup = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > The Home Group </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>BECOMING PART OF</b><br></br>It is the common experience for many of us to feel like we didn’t fit in anywhere. Drugs gave us that instantaneous feeling of belonging. When we get sober, that drug-induced feeling of belonging disappears. Finding a group of sober people we can relate to is a new start for us. We go to meetings as often as possible, preferably every day. Before long, we find we are most comfortable at one particular meeting. Because we feel “at home” there, we join the home group and commit to attending regularly. Other home group members get to know who we are, and we get to know them. We never have to be alone again. On a bad day, we find there is usually someone there who understands and who can help us get through it sober.</p>
                                    <p><b>THE HOME GROUP</b><br></br>As newcomers, we hear the suggestion to get a “home group,” but we aren’t sure why. Many of us have been in isolation for the last months or years of our using. We feel apart from society and need to connect with people who understand. We find those people at meetings of Cocaine Anonymous.</p>
                                    <p><b>TEMPORARY SPONSORSHIP</b><br></br>Since our home group gets to know us quite well, they can sometimes act as our sponsor at the beginning of our recovery until we get one of our own. They give us suggestions and listen to our troubles. They explain what the Steps are for and may even get us started on the work. They give us hope to continue on our journey.</p>
                                    <p><b>A POWER GREATER THAN OURSELVES</b><br></br>Sometimes it is difficult to develop a concept of a Power greater than ourselves. Our drugs had been such a power, but what could be greater than that? As we begin to work a program of recovery, we learn that we are defenseless against our addiction. We need a Power greater than ourselves, greater than our addiction. There may be no better place to start than with a group of sober addicts. Our own concept of a Higher Power will come in time as we work the Twelve Steps. Until then, it is good to have the power of the group with us.</p>
                                    <p><b>TWELVE-STEP WORK/SERVICE</b><br></br>We often wonder how we can carry the message to the addict who still suffers before we get through working all of the Steps. The answer is service. Wasn’t it important to us to have someone at the door to welcome us? Was there coffee made? Did someone take the time to greet us? Were there chairs set up? Who read the readings at the beginning of the meeting? Was there someone to empty the ashtrays? We can find a way to help no matter how long we have been sober. We are told that getting out of ourselves is one of the best things for us to do, and serving our home group is a wonderful way to start.</p>
                                    <p><b>RESPONSIBILITY</b><br></br>In our active addiction, we were often irresponsible and unaccountable. We often placed blame on others and none on ourselves. Taking on responsibility at our home group is a good step forward. Although you probably won’t become treasurer at your first business meeting, you might become the coffee person, showing up early to make sure the coffee is ready for the early birds and staying late to clean up the pots and cups. There are a variety of positions that need to be filled at most groups: Chairperson, Secretary, Group Service Representative (GSR), Greeter, Coffee Maker, Chips and Literature Representative, Floor Sweeper and others. All are very important. There is always something you can do, no matter how long you have been sober. Being of service at your home group is a great way to practice being a responsible member of society.</p>
                                    <p>The benefits of becoming involved in a home group are more than you can probably imagine. It can be an important part of the process that ultimately keeps us clean and sober. One of our original members writes, “It occurred to me that when many of us walk through the doors of Cocaine Anonymous for the first time, we do so without hope, without faith, but with an ounce of courage. We listen to the stories and experiences of others and develop hope. As a result of coming back, and coming back, and coming back and working the Twelve Steps, we acquire an abundance of faith.” (Hope, Faith & Courage Volume II, p. 6.)</p>
                                    <p>We encourage you to keep going to meetings until you find yourself a home group—and no matter what, keep coming back.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/The-Home-Group.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default TheHomeGroup
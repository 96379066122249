import React from 'react'

const ToolsOfRecovery = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Tools of Recovery </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>There comes a time when the cocaine stops working—a time when the coke, the other drugs, and all the madness become unbearable. By then, you just can’t stop, so you manage to score and somehow survive. You keep on using because, although it’s killing you, cocaine has become the most important thing in your life. If you somehow, some way, get a break from it, get free for a moment with a little clarity, you will know this could be your last chance. You must stop using now, and you are really scared. You want to stay away from cocaine, but you don’t know how.</p>
                                    <p>If you want to be clean and sober, you can be. If you want what we have, you can have it. No matter how much cocaine you have used or how low you have sunk, you can get away and stay away from cocaine, by doing what we have done. Thousands of recovering cocaine addicts are living drug-free and owning their lives again, by actively using the tools of recovery in the program of Cocaine Anonymous. Here are some of the tools that work for us.</p>
                                    <p><b>TOTAL ABSTINENCE</b><br></br>Those of us who have lost control of our cocaine consumption must abstain from all mind-altering substances. It’s our experience that our addiction is invariably triggered by the use of alcohol and other drugs. Just don’t drink or use—no matter what.</p>
                                    <p><b>MEETINGS</b><br></br>We attend meetings to share our experience, strength, and hope with other recovering addicts. What we failed to do alone we can do together. We also learn valuable information about our disease and how the program of Cocaine Anonymous works in our lives. We suggest that you get a meeting directory and go to 90 meetings in 90 days.</p>
                                    <p><b>LITERATURE</b><br></br>The books <i>Alcoholics Anonymous</i> (the "Big Book") and <i>Twelve Steps and Twelve Traditions</i> (the “Twelve and Twelve”) of Alcoholics Anonymous are two of our most valuable tools of recovery. In addition, Cocaine Anonymous publishes numerous pieces of literature to further help the recovering addict, including our books, <i>Hope, Faith &amp; Courage: Stories from the Fellowship of Cocaine Anonymous</i>, and <i>Hope, Faith &amp; Courage Volume II: Stories and Literature from the Fellowship of Cocaine Anonymous</i>.</p>
                                    <p><b>SPONSOR</b><br></br>A sponsor is another recovering addict who has more sobriety and program experience than yourself. He or she (same sex is recommended) will help you work the Steps and should be someone you feel comfortable communicating with. We recommend that you begin looking for a sponsor immediately. You can change sponsors later if the relationship doesn’t work out.</p>
                                    <p><b>THE TWELVE STEPS</b><br></br>Meetings may keep you sober for some time, but the Twelve Steps of Cocaine Anonymous are vital for a stable and happy recovery. The Steps of Cocaine Anonymous are the means by which we move from the problem of drug addiction to the solution of recovery. We learn about the Steps by reading the literature, attending Step study meetings, and working with a knowledgeable sponsor.</p>
                                    <p><b>HIGHER POWER</b><br></br>We urge new members to explore whatever beliefs they may have in a Power greater than themselves. There are no religious requirements or beliefs necessary for membership in Cocaine Anonymous. Some of us had lost our spirituality before we came to C.A., and others of us never had any. As we recovered, many of us experienced new or reawakened spiritual feelings. It helps to be open-minded.</p>
                                    <p><b>SERVICE</b><br></br>One of the keys to successful recovery is getting involved. Begin by taking on tasks and keeping commitments at meetings—make coffee; help clean up; put away chairs. Help yourself by helping others.</p>
                                    <p><b>TELEPHONE</b><br></br>The telephone is our lifeline between meetings. Get phone numbers from other C.A. members. We are usually shy about calling at first, but we must find a way to do it. We suggest you call someone in the program daily.</p>
                                    <p><b>ONE DAY AT A TIME</b><br></br>The thought of making a pledge never to use again can be discouraging. We stay clean and sober one day at a time and if necessary, one hour or even one minute at a time.</p>
                                    <p><b>PRAYER AND MEDITATION</b><br></br>We use these tools to establish and improve our conscious contact with God, as we understand Him. We have found the Serenity Prayer to be very helpful:</p>
                                    <p className="has-text-centered	">God, grant me the serenity to accept the things I cannot change,<br></br>
                                    courage to change the things I can,<br></br>
                                    and wisdom to know the difference.</p>
                                    <p>Make your recovery your number one priority. All your hopes and plans, even your very survival, depend on a drug-free you. Staying away from cocaine and all other mind-altering substances may be the greatest challenge you will ever face. The early period can be tough, but that doesn’t mean you are not getting better. Beware of thoughts like, “I don’t feel good,” or “This is not working.” Recovery is a process, and it takes time.</p>
                                    <p><b>We hope that by using these tools you will find the same joy and freedom we have found. Just remember to be patient and keep coming back.</b></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Tools-Of-Recovery.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ToolsOfRecovery
import React from 'react'

const choosing_your_sponsor = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Choosing Your Sponsor </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>Why Sponsorship?</b></p>
                                    <p>You’ve likely heard lots of talk at Cocaine Anonymous meetings about working the Twelve Steps of Recovery, recovery, finding a Power greater than ourselves, and getting a sponsor. If you’re like most newcomers, however, you’re probably not sure what all these terms mean.</p>
                                    <p>This is why it’s a good idea to get a sponsor. A sponsor can devote individual attention to answering your questions regarding the C.A. program of recovery. It can also help you feel more comfortable to be with someone who knows his or her way around. A sponsor can introduce you to other C.A. members, help you get involved in service and encourage you to participate in Fellowship events, all of which are vital to a successful program of recovery.</p>
                                    <p>We can benefit immensely from one-on-one support as we begin learning how to live in recovery. Many of us would not have been able to stay clean and sober were it not for the special relationship with our sponsor. Sharing difficulties with a sponsor makes the struggle less lonely and the day-to-day living a lot easier.</p>
                                    <p><b>What is a Sponsor?</b></p>
                                    <p>A sponsor is a recovering addict who shares one-on-one how to maintain sobriety by working the Twelve Steps of C.A. A sponsor’s primary tools are his or her own experience, strength and hope. The Steps can seem like a foreign language at first, but a sponsor can help us understand this new way of living and explain how each Step brings us closer to a Higher Power that can remove our obsession to use.</p>
                                    <p>Sponsors are not professional counselors and are not certified to offer legal, psychiatric or medical advice. Nor should we become dependent on our sponsors to get us jobs, clothing or food. However, sponsors may have experience with how to obtain professional help or other resources.
                                    </p>
                                    <p><b>A Discussion of Sponsorship</b>
                                    </p>
                                    <p>There are no specific rules for sponsorship in C.A., but we do offer a few suggestions for your consideration.
                                    </p>
                                    <p>Experience has shown that it’s best to avoid the possibility of emotional and/or physical distractions that might take the newcomer’s mind off the purpose of Cocaine Anonymous. For this reason, it may be wise to choose a sponsor of the same sex if we’re heterosexual, and of the opposite sex if we’re gay or lesbian.</p>
                                    <p>Which sponsor is best for you? Only you can answer that question. You and your sponsor may have a lot in common or you may be totally different.</p>
                                    <p>Sponsorship can involve a substantial amount of time and energy. To have a successful relationship, both parties should be willing to communicate on a regular basis.
                                    </p>
                                    <p>It is also imperative that the sponsor provides a safe and supportive atmosphere. The sponsor should be careful not to share a sponsee’s confidences with anyone else. This will help build a foundation of trust and encourage the sponsee to be honest. A sponsor needs to know what is truly going on in order to help the sponsee.</p>
                                    <p>At times, we may feel uncomfortable with what our sponsor suggests. When this happens, it’s important to remember that our sponsor has traveled the road before us and is sharing his or her experience to help us through difficult times.
                                    </p>
                                    <p>It is your responsibility to call your sponsor, so do not hesitate to pick up the phone. It may be difficult at first to ask for help, but contacting your sponsor on a regular basis is one of the first ways you can take responsibility for your own recovery.</p>
                                    <p>Remember, sponsors have lives outside of C.A. They have families, jobs and other responsibilities. There will be times when a sponsor is truly unavailable. When this happens, check the listings for the next local C.A. meeting, or perhaps join one of the online C.A. meetings. You could also read C.A. literature, contact the local C.A. helpline, or pull out your phone list. Keep an active list of recovering addicts’ phone numbers with you at all times and above all CALL. Remember, our program is based on one addict helping another. The help is there, ready and willing to be shared, but you have to reach out and ask for it.</p>
                                    <p>Working the Steps is not an overnight matter. It requires continuous action and a persistent look at the things that have blocked us from a Power greater than ourselves. Sobriety depends on maintaining fit spiritual condition, which we do by continuously working the Steps. This is why we suggest ongoing contact with a sponsor no matter how long you have been sober.</p>
                                    <p><b>Finding a Sponsor</b></p>
                                    <p>Some of the ways we have found a sponsor are:</p>
                                    <p><ul>
                                        <li>Listening to and identifying with the feelings being shared at meetings;</li>
                                        <li>Noticing when someone has been where we have been and now has the kind of sober life we hope to have;</li>
                                        <li>Asking members of the Fellowship for their phone numbers, then calling between meetings;</li>
                                        <li>Sharing at meetings that we are looking for a sponsor;</li>
                                        <li>Asking other C.A. members to recommend a sponsor;</li>
                                        <li>Going to coffee after meetings with other C.A. members;</li>
                                        <li>Attending C.A. activities or events.</li>
                                    </ul></p>
                                    <p>When choosing a sponsor, remember that this does not have to be a life-long relationship. Many of us have changed sponsors during the course of our sobriety. Others have had the same sponsor since early sobriety. The point is that YOU must take the initiative and reach out.</p>
                                    <p>It is never too late to get a sponsor. Whether you are a newcomer hesitant about “bothering” someone, or a member who has been around for some time trying to go it alone, sponsorship is yours for the asking.</p>
                                    <p>Most C.A. members owe their sobriety to the fact that someone else was willing to share a great gift with them. Getting a good sponsor, sharing honestly and listening to his or her experience can make the whole program open up as it never did before.</p>
                                    <p>We in Cocaine Anonymous urge you: DO NOT DELAY. We want to share what we have learned with other addicts because experience has taught us that we keep what we have by giving it away. You will likely be helping your sponsor as much as your sponsor is helping you.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Choosing-Your-Sponsor.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default choosing_your_sponsor
import React from 'react'

const BeingOfService = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Being of Service </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>In addition to finding a spiritual way of life, recovery is about changing negative aspects of our personalities into positive ones. We came into the program with big egos but little or no self-esteem. We thought we were better than other people yet, at the same time, felt “less than.” We were people who took from others and abused friendships all of our lives. We had no concept of doing anything for anyone without the thought of some kind of reward. By the sheer grace of our Higher Powers, we have found several ways of unlearning such behavior in the program. One way is to be of service to the Fellowship of Cocaine Anonymous. We discovered that the best way to serve God was to serve our fellow humans, and we found humility in the process. Our self-centered behavior was gradually replaced with the “attitude of service.” We learned that service is about gratitude and learning how to contribute to our lives and the lives of others.</p>
                                    <p><b>Why Be of Service?</b>
                                        <ul>
                                            <li>To give back what was so freely given to us;</li>
                                            <li>To take on a commitment as a symbolic way of making amends;</li>
                                            <li>To meet other recovering addicts;</li>
                                            <li>To learn how to be part of a team;</li>
                                            <li>To learn humility by doing something selfless for someone else;</li>
                                            <li>To learn skills or teach others what we know;</li>
                                            <li>To learn responsibility.</li>
                                        </ul>
                                    </p>
                                    <p><b>Ways to Be of Service</b></p>
                                    <p><b>Service Opportunities at the Meeting and Area Level:</b><br></br>
                                        <b>Meeting Setup:</b> Arrive early to set up chairs, make coffee, and greet newcomers.<br></br>
                                        <b>Hotline:</b> Did you find C.A. through a hotline? Answer the phones for your Area’s hotline and be on the “front line” of helping others find C.A. and recovery.<br></br>
                                        <b>Treasurer:</b> Pass the 7th Tradition basket and keep track of funds for a meeting—it’s a great way to learn responsibility.<br></br>
                                        <b>GSR/DSR (Group/District Service Representative):</b> Get involved in the business aspects of C.A. by representing one of your meetings or Districts at your District/Area business meeting.<br></br>
                                        <b>Secretary:</b> Ensure that a meeting will always be there for those who need it by choosing speakers, paying the rent, and keeping it running smoothly and consistently. You might even consider helping the Fellowship grow by starting a new meeting.
                                    </p>
                                    <p>
                                        <b>Committees at the Area and/or World Service Level:</b><br></br>
                                        <b>Convention:</b> Help plan big sober parties and carry the message of recovery at the same time.<br></br>
                                        <b>Literature:</b> If you have writing skills or ideas that could reach out in print to people who are still suffering, express those ideas on this World Service committee.<br></br>
                                        <b>Finance:</b> Put your accounting, business, or money management skills to good use in C.A.’s financial matters.<br></br>
                                        <b>Public Information:</b> Reach out through the media to those who have never heard of C.A.<br></br>
                                        <b>Hospitals &amp; Institutions:</b> Help develop new ways to reach out to people who are unable to reach out for themselves.<br></br>
                                        <b>Unity:</b> Enhance communication and outreach among the diverse elements within our Fellowship.<br></br>
                                        <b>Structure &amp; Bylaws:</b> Formulate bylaws, guidelines, and structures by which C.A. can operate.<br></br>
                                        <b>Conference:</b> Help organize the World Service Conference each year, where delegates from all over the world meet to conduct C.A. business.<br></br>
                                        <b>Area &amp; World Service Office Boards of Directors:</b> Serve as a Chairperson, Vice-Chairperson, Secretary, Treasurer, or Director at Large. Conduct the day-to-day business operations of C.A. to ensure that it is there for newcomers the way it was there for you.<br></br>
                                    </p>
                                    <p><i>Ask your GSR who you can contact for more information about how to be of service.</i></p>
                                    <p><b>Suggestions</b><br></br>
                                        <ul>
                                            <li>Share a commitment, such as a hotline slot, if your schedule doesn’t allow you to have one of your own.</li>
                                            <li>Find a place where you can be of service and then give 100%.</li>
                                            <li>Volunteer at a meeting when help is asked for or better yet, before it is asked for.</li>
                                            <li>Encourage the people you sponsor to take on commitments and then help them to keep those commitments.</li>
                                            <li>Ask your sponsor what type of service commitment you could take on that would best serve the Fellowship.</li>
                                            <li>Adopt the attitude of service without reward.</li>
                                        </ul>
                                    </p>
                                    <p><b><i>Remember, time spent working on a commitment is time not spent getting loaded.</i></b></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Being-of-Service.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default BeingOfService
import React from 'react'
import Modal from '../Modal/Modal';
import './About.css'

class About extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modalType: '',
      modalContent: '',
      modalShow: false
    };
  }

  _setModal = (img, type ,e) => {
    e.preventDefault();
    this.setState({ 
        modalType: type,
        modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props)=> {
      const newState = !prev.modalShow;
      return { modalShow : newState}
    });
  }

  render(){
    return (
      <React.Fragment>
      <section className="section">
        <div className = "container about" >
          <h1 className = "title" > About Us </h1>
            <div className="columns">
              <div className="column">
              <div className="content box has-background-white">
                <p><strong>
                Cocaine Anonymous is a Fellowship of men and women who share their experience, strength and hope with each other that they may solve their common problem and help others recover from their addiction.
                </strong></p>
                <p>
                The best way to reach someone is to speak to them on a common level. The members of C.A. are all recovering addicts who maintain their individual sobriety by working with others. We come from various social, ethnic, economic and religious backgrounds, but what we have in common is addiction.
                </p>            
                <p>
                <strong>
                The only requirement for membership is a desire to stop using cocaine and all other mind-altering substances.
                </strong>
                </p>
                <p>
                Anyone who wants to stop using cocaine and all other mind-altering substances (including alcohol and other drugs) is welcome.
                </p>
                <p>
                <strong>
                There are no dues or fees for membership; we are fully self-supporting through our own contributions.
                </strong>
                </p>
                <p>
                We do ask for voluntary contributions at meetings to cover expenses such as coffee, rent, literature and services to help those who are still suffering. However, newcomers need not feel obligated to contribute. We do not accept donations from organizations or individuals outside the Fellowship.
                </p>
                <p>
                  <strong>
                  We are not allied with any sect, denomination, politics, organization or institution.
                  </strong>
                </p>
                <p>
                In order to maintain our integrity and avoid any possible complications, we are not affiliated with any outside organization. Although C.A. is a spiritual program, we do not align ourselves with any religion. Our members are free to define their spirituality as they see fit. Our individual members may have opinions of their own, but C.A. as a whole has no opinion on outside issues. We are not affiliated with any rehabs, recovery houses or hospitals, but many do refer their patients to Cocaine Anonymous to maintain their sobriety.
                </p>
                <p>
                  <strong>
                  Our primary purpose is to stay free from cocaine and all other mind-altering substances and to help others achieve the same freedom.
                  </strong>
                </p>
                <p>
                The only purpose of Cocaine Anonymous is to offer recovery to individuals who are suffering from addiction. Our experience has shown that the most effective way to attain and maintain sobriety is to work with others suffering from the same malady.
                </p>
                <p>
                  <strong>
                  We use the Twelve-Step recovery program because it has already been proven that the <a href="/12and12">Twelve-Step</a> recovery program works.
                  </strong>
                </p>
              </div>
              </div>
            </div>
        </div > 
      </section>
      <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} modalState={this.state.modalShow} />
      </React.Fragment>
    );
  }
}
 
export default About;
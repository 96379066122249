import React from 'react'

const CALifeline = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > C.A. Lifeline </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>Those who found Cocaine Anonymous during treatment may recall their fear and anxiety about attending a meeting for the first time after completing treatment. The transition can be extremely difficult, from not knowing anyone, to not knowing exactly what to do or say, or even knowing where to sit! Yet, we were desperately seeking a solution to our addiction and hoping to find it in a C.A. meeting.</p>
                                    <p>C.A. Lifeline addresses this by connecting with the newcomer to accompany them to their first meeting after treatment. Personally welcoming a newcomer, introducing them to some of our friends, and finding them a seat with us can go a long way toward helping them relax and be able to hear the message. By doing so, we extend a “Lifeline” to the newcomer and share our hope, faith and courage. We all want to give others what was so freely given to us, and this is a wonderful way to do so. Start a C.A. Lifeline Subcommittee in your Area!</p>
                                    <p><b>Statement of Purpose</b></p>
                                    <p>C.A. Lifeline’s primary purpose is to help the newcomer addict’s transition from treatment to the rooms of Cocaine Anonymous. We do this by providing a temporary contact to a C.A. member in the newcomer’s local area.</p>
                                    <p>Members of Cocaine Anonymous, usually acting in pairs, meet the patients after their release from treatment and accompany them to their first C.A. meeting. Participation in the C.A. Lifeline is always voluntary.</p>
                                    <p>The C.A. Lifeline committee’s activities include:</p>
                                    <p>
                                        <ol>
                                            <li>Provide a vital link between treatment and the fellowship of C.A.</li>
                                            <li>Meet and correspond with representatives of local treatment centers to ask for permission to work with their patients</li>
                                            <li>Correspond with patients desiring C.A. Lifeline on release.</li>
                                            <li>Maintain a list of C.A. volunteers willing to do C.A. Lifeline work.</li>
                                            <li>Print and distribute appropriate formats.</li>
                                        </ol>
                                    </p>
                                    <p>Our committee is a subcommittee of the H &amp; I Committee. We also send a liaison to the District and/or Area meetings.</p>
                                    <p><b>Basic Functions of the C.A. Lifeline Sub-committee*</b>
                                    </p>
                                    <p>Presentation Teams:</p>
                                    <p>
                                        <ol>
                                            <li>Make the initial presentation to the administrators of the facilities.</li>
                                            <li>Make routine presentations to patients.</li>
                                            <li>Gather contact requests from patients.</li>
                                        </ol>
                                    </p>
                                    <p>Phone Coordinators:</p>
                                    <p>
                                        <ol>
                                            <li>Call temporary Lifeline contacts from the phone list and give them the contact information from the newcomer’s contact request card so the Lifeline member may reach out to the newly released patient.</li>
                                            <li>Ask temporary contacts to contact the Follow-up Coordinator.</li>
                                            <li>Forward contact requests for other Districts/Areas to the appropriate District/Area Chair.</li>
                                        </ol>
                                    </p>
                                    <p>Temporary Contacts: After the patient is released, Temporary Contacts, preferably in groups of two, will accompany the released patient to an outside C.A. meeting and then contact the Follow-Up Coordinator.</p>
                                    <p>Follow-Up Coordinators: Check on success of contact and provide follow-up report to the C.A. Lifeline Chairperson.</p>
                                    <p>C.A. Lifeline Chair: Reports to the District/Area H &amp; I Committee about work and progress within the Fellowship.</p>
                                    <p>Numerous treatment facilities have welcomed C.A. Lifeline, giving Lifeline members the opportunity to tell patients about the Fellowship of Cocaine Anonymous. As a result, Lifeline has helped countless newcomers get to a meeting after leaving rehab, sometimes their first meeting.</p>
                                    <p>Some patients may or may not sign up, some may or may not answer when released, some may or may not accept the Lifeline offer, and some may or may not attend another meeting. The bottom line is that they now know there are others who have recovered from addiction and there is a way out.</p>
                                    <p>Those who do stay are already immersed in the Fellowship. They have a point of contact and are easily introduced to many other members of Cocaine Anonymous.</p>
                                    <p>Our hope is to help overcome the newcomer’s hesitations about attending a meeting after treatment, whether those include denial, inconvenience, fear, over-confidence, or limited resources. Simply having a fellow addict and member of Cocaine Anonymous accompany the newcomer to a meeting can encourage them to stay.</p>
                                    <p>For the subcommittee member, Lifeline offers the opportunity to experience all sides of the service triangle: recovery, unity and service. Participating in Lifeline helps the C.A. Fellowship stay strong and continue to grow.</p>
                                    <p>*For additional information on C.A. Lifeline, please refer to the Cocaine Anonymous World Service Conference Hospitals and Institutions Committee Suggested Guidelines and Information, available in the Service section at www.ca.org.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/ca-lifeline.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default CALifeline
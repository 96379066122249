import React from 'react'

const ThePromises = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > The Promises </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                            If we are painstaking about this phase of our development, we will be amazed before
                            we are half way through. We are going to know a new freedom and a new happiness.
                            We will not regret the past nor wish to shut the door on it. We will comprehend the word
                            serenity and we will know peace. No matter how far down the scale we have gone, we
                            will see how our experience can benefit others. That feeling of uselessness and self-pity
                            will disappear. We will lose interest in selfish things and gain interest in our fellows. Selfseeking will slip away. Our whole attitude and outlook upon life will change. Fear of
                            people and of economic insecurity will leave us. We will intuitively know how to handle
                            situations which used to baffle us. We will suddenly realize that God is doing for us what
                            we could not do for ourselves.</p>
                        <p>
                            Are these extravagant promises? We think not. They are being fulfilled among us—
                            sometimes quickly, sometimes slowly. They will always materialize if we work for them.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ThePromises
import React from 'react'

const The7thTradition = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > The 7th Tradition </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>C.A.’s 7th Tradition reads as follows: "Every C.A. group ought to be fully self-supporting, declining outside contributions."</p>
                                    <p>You’re probably like many of us were when we first came into Cocaine Anonymous—we put our money into the basket and then saw it collected by a person we thought must be the boss or president of C.A. At first this didn’t seem like a big deal, but eventually we wondered, “Where does that money go?” To answer this question, let’s follow the money in the basket.<br></br>The treasurer collects the money and uses it to pay the Group’s expenses. The treasurer also gives the Group regular reports on the total donations collected and expenses paid, as well as any amounts left over. A typical Group’s expenses include the following:</p>
                                    <p>Rent: Cocaine Anonymous is not affiliated with the hospitals, churches, or other facilities where our meetings are held; we pay rent and/or make a donation for the use of their space. This arrangement is mandated by Tradition 6 and our preamble, which state that we are not allied with any sect, denomination, politics, organization, or institution. Paying for the use of its meeting space is part of the Group being self-supporting through its own members’ contributions.</p>
                                    <p>Chips and literature: Chips and literature are purchased from the C.A. World Service Office, usually via the local District or Area. The meeting’s secretary or chips &amp; literature person is responsible for keeping track of these items. When the supply of any of them gets low, he or she puts together an order and gives it to the treasurer. The treasurer then takes money from the donations in the basket and gives the order form and money to your meeting’s Group Service Representative (GSR). The GSR takes the order form and the money to the monthly District or Area meeting and gets the chips &amp; literature to bring back to the meeting. Methods vary, but this is the way many meetings operate.</p>
                                    <p>Coffee and/or tea: The coffee or tea person typically buys the beverage supplies and possibly even snacks prior to the meeting and sets up before everyone else arrives.</p>
                                    <p>What happens to the leftover money? After all these expenses are paid, it is suggested that each Group keep enough of the leftover donations to cover two or three months’ worth of expenses. This is called a prudent reserve. Each Group should decide by group conscience what its prudent reserve amount is. The treasurer should maintain this amount, and all other monies should be sent on to the next level of the service structure as a donation. The following table is provided to help calculate each meeting’s prudent reserve:</p>
                                    <p>
                                    Rent per month $______(1)<br></br>
                                    Chips &amp; literature per month $______(2)<br></br>
                                    Beverages/snacks per month $______(3)<br></br>
                                    Total estimated monthly expenses<br></br>
                                    (add lines 1, 2 &amp; 3) $______(4)<br></br>
                                    Prudent reserve: Multiply line 4<br></br>
                                    by 2 or 3 months (as determined<br></br>
                                    by group conscience) $______<br></br>
                                    </p>
                                    <p>What happens to the rest of the money? This is where the 70/30 plan comes in. With the 70/30 plan, 70% of the Group’s funds in excess of the prudent reserve are donated to the District/Area and 30% are donated to the C.A. World Service Office.* As Tradition Five states, “Each group has but one primary purpose—to carry its message to the addict who still suffers.” We’ve found that nothing diverts us from our primary purpose like problems of money, property, and prestige.</p>
                                    <p>What do the Districts/Areas do with this money? Districts and Areas exist to serve the Groups. Each District/Area also has expenses that can include a hotline’s telephone bill or answering service, maintaining a website and publishing meeting schedules. It typically also purchases chips and literature, keeping enough in stock for the Groups it serves. Districts and Areas should also keep prudent reserves.</p>
                                    <p>What does the C.A. World Service Office (CAWSO) do with this money? CAWSO is responsible for educating the international community about the program of recovery of Cocaine Anonymous and to help carry the message to those addicts who still suffer. The WSO, the focal contact and distribution point of CA, places a heightened purpose on maintaining contact with members, Groups, Districts, and Areas. Contact is sustained through correspondence, newsletters, e-mail, the C.A. website (www.ca.org), and trusted servants within our service structure. These are just a few of the many responsibilities of the CAWSO.</p>
                                    <p>According to Tradition Two, “Our leaders are but trusted servants; they do not govern.” Once our term of commitment has expired, we step down and a successor is elected. Our service boards and committees have been created to help the addict who is still suffering; to give back what we have freely received. Being of service is another way we help our Groups be fully self-supporting.</p>
                                    <p>The basket is where spirituality and money mix. The next time you put your money into the basket, think of all the things that are being accomplished with those funds and how you are helping the addict who is still suffering.</p>
                                    <p>*The mailing address for the World Service Office can be found on the front of this pamphlet and http://ca.org/contact-us/</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/The-7th-Tradition.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default The7thTradition
import React from 'react'

const Privacy = () => (
  <section className="section">
    <div className="container" >
      <h1 className="title" > Privacy &amp; Security Policy </h1>
      <div className="columns">
        <div className="column">
          <div className="box has-background-white content">
            <p>
              "A vast communications net now covers the earth, even to its remotest reaches... nothing matters more to AA’s future welfare than the manner in which we use the colossus of modern communication. Used unselfishly and well, it can produce results surpassing our present imagination."
              Bill W., The AA Grapevine, Inc., November 1960
          </p>
            <p>
              This is the privacy policy (“Privacy Policy”) for Cocaine Anonymous® New York ("CANY"), Inc., website (the “Site”). The Site is made available by Cocaine Anonymous® New York ("CANY"), Inc. (“Cocaine Anonymous® New York” or “us” or “our” or “we”). This Privacy Policy sets forth our policies regarding the collection, use and disclosure of certain information relating to you and your use of the Site. Your use of this Site signifies your understanding and acceptance of the terms of this Privacy Policy.
          Cocaine Anonymous® New York, values the privacy and security of our Site users. To that end, we take measures to protect your personal information and the security of our Site.           </p>
            <p className="subtitle">
              Personal Information
          </p>
            <p>
              We do not ask for or collect personal information from our Site visitors.
          </p>
            <p className="subtitle">
              Security          </p>
            <p>
              We have put in place physical, electronic, and managerial procedures designed to help prevent unauthorized access. Although we take appropriate measures to safeguard against unauthorized access, we cannot assure you that information will never be accessed in a manner that is inconsistent with this Privacy Policy.
          </p>
            <p className="subtitle">
              Third-Party Sites
          </p>
            <p>
              This may contain links or references to other websites outside of our control. Please be aware that we have no control over these sites and our Privacy Policy does not apply to these sites. Third party sites may also use tracking technologies by or through the Site. We have no responsibility or liability for any tracking, data collection or other activities of such third parties.
          </p>
            <p className="subtitle">
              Your Acceptance of this Policy
          </p>
            <p>
              By using this Site, you signify your acceptance of our Privacy Policy. If you do not agree to this Privacy Policy, please do not use our Site. We reserve the right, at our discretion, to modify this Privacy Policy at any time with or without notice to you; therefore, Site users are encouraged to review this Privacy Policy from time to time. Your continued use of our Site following the posting of changes to this Privacy Policy means you accept any such modifications.
          </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Privacy
import React from 'react'

class Steps extends React.Component {
  render(){
    return (
      <React.Fragment>
      <section className="section">
        <div className = "container" >
          <h1 className = "title" > The Twelve Steps and Twelve Traditions </h1>
            <div className="columns">
              <div className="column is-four-fifths">
                <p><strong>
                The Twelve Steps of Cocaine Anonymous<br/>
                </strong>
                The Twelve Steps describe the Program of Recovery used by Cocaine Anonymous
                </p>
                <p>
                1. We admitted we were powerless over cocaine and all other mind-altering substances — that our lives had become unmanageable.<br/>
                2. Came to believe that a Power greater than ourselves could restore us to sanity.<br/>
                3. Made a decision to turn our will and our lives over to the care of God as we understood Him.<br/>
                4. Made a searching and fearless moral inventory of ourselves.<br/>
                5. Admitted to God, to ourselves, and to another human being the exact nature of our wrongs.<br/>
                6. Were entirely ready to have God remove all these defects of character.<br/>
                7. Humbly asked Him to remove our shortcomings.<br/>
                8. Made a list of all persons we had harmed, and became willing to make amends to them all.<br/>
                9. Made direct amends to such people wherever possible, except when to do so would injure them or others.<br/>
                10. Continued to take personal inventory and when we were wrong promptly admitted it.<br/>
                11. Sought through prayer and meditation to improve our conscious contact with God as we understood Him, praying only for knowledge of His will for us and the power to carry that out.<br/>
                12. Having had a spiritual awakening as the result of these steps, we tried to carry this message to addicts, and to practice these principles in all our affairs.<br/>
                </p>            
                <p>
                The Twelve Steps are reprinted with permission of Alcoholics Anonymous World Services, Inc. Permission to reprint and adapt the Twelve Steps does not mean that A.A. is affiliated with this program. A.A. is a program of recovery from alcoholism. Use of the Steps in connection with programs and activities which are patterned after A.A. but which address other problems does not imply otherwise. THE TWELVE STEPS OF ALCOHOLICS ANONYMOUS: 1. We admitted we were powerless over alcohol—that our lives had become unmanageable. 2. Came to believe that a Power greater than ourselves could restore us to sanity. 3. Made a decision to turn our will and our lives over to the care of God as we understood Him. 4. Made a searching and fearless moral inventory of ourselves. 5. Admitted to God, to ourselves, and to another human being the exact nature of our wrongs. 6. Were entirely ready to have God remove all these defects of character. 7. Humbly asked Him to remove our shortcomings. 8. Made a list of all persons we had harmed, and became willing to make amends to them all. 9. Made direct amends to such people wherever possible, except when to do so would injure them or others. 10. Continued to take personal inventory and when we were wrong promptly admitted it. 11. Sought through prayer and meditation to improve our conscious contact with God as we understood Him, praying only for knowledge of His will for us and the power to carry that out. 12. Having had a spiritual awakening as the result of these Steps, we tried to carry this message to alcoholics, and to practice these principles in all our affairs.
                </p>
                <p>
                <strong>
                The Twelve Traditions of Cocaine Anonymous
                </strong><br/>
                The Twelve Traditions comprise the organizational guidelines under which the autonomous groups of C.A. function
                </p>
                <p>
                1. Our common welfare should come first; personal recovery depends upon C.A. unity.<br/>
                2. For our group purpose there is but one ultimate authority — a loving God as He may express Himself in our group conscience. Our leaders are but trusted servants; they do not govern.<br/>
                3. The only requirement for C.A. membership is a desire to stop using cocaine and all other mind-altering substances.<br/>
                4. Each group should be autonomous except in matters affecting other groups or C.A. as a whole.<br/>
                5. Each group has but one primary purpose — to carry its message to the addict who still suffers.<br/>
                6. A C.A. group ought never endorse, finance, or lend the C.A. name to any related facility or outside enterprise, lest problems of money, property and prestige divert us from our primary purpose.<br/>
                7. Every C.A. group ought to be fully self-supporting, declining outside contributions.<br/>
                8. Cocaine Anonymous should remain forever nonprofessional, but our service centers may employ special workers.<br/>
                9. C.A., as such, ought never be organized; but we may create service boards or committees directly responsible to those they serve.<br/>
                10. Cocaine Anonymous has no opinion on outside issues; hence the C.A. name ought never be drawn into public controversy.<br/>
                11. Our public relations policy is based on attraction rather than promotion; we need always maintain personal anonymity at the level of press, radio, television and films.<br/>
                12. Anonymity is the spiritual foundation of all our Traditions, ever reminding us to place principles before personalities.<br/>
                </p>
                <p>
                The Twelve Traditions are reprinted with permission of Alcoholics Anonymous World Services, Inc. Permission to reprint and adapt the Twelve Traditions does not mean that A.A. is affiliated with this program. A.A. is a program of recovery from alcoholism. Use of the Traditions in connection with programs and activities which are patterned after A.A. but which address other problems does not imply otherwise. THE TWELVE TRADITIONS OF ALCOHOLICS ANONYMOUS: 1. Our common welfare should come first; personal recovery depends upon A.A. unity. 2. For our group purpose there is but one ultimate authority—a loving God as He may express Himself in our group conscience. Our leaders are but trusted servants; they do not govern. 3. The only requirement for A.A. membership is a desire to stop drinking. 4. Each group should be autonomous except in matters affecting other groups or A.A. as a whole. 5. Each group has but one primary purpose—to carry its message to the alcoholic who still suffers. 6. An A.A. group ought never endorse, finance, or lend the A.A. name to any related facility or outside enterprise, lest problems of money, property and prestige divert us from our primary purpose. 7. Every A.A. group ought to be fully self-supporting, declining outside contributions. 8. Alcoholics Anonymous should remain forever nonprofessional, but our service centers may employ special workers. 9. A.A., as such, ought never be organized; but we may create service boards or committees directly responsible to those they serve. 10. Alcoholics Anonymous has no opinion on outside issues; hence the A.A. name ought never be drawn into public controversy. 11. Our public relations policy is based on attraction rather than promotion; we need always maintain personal anonymity at the level of press, radio and films. 12. Anonymity is the spiritual foundation of all our Traditions, ever reminding us to place principles before personalities.
                </p>
              </div>
            </div>
        </div > 
      </section>
      </React.Fragment>
    );
  }
}
 
export default Steps;
import React from 'react'

const CAIsAlsoForTheLGTQPlusAddict = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > C.A. is <i>Also</i> for the LGBTQ+ Addict </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><i>"The first time I shared my story at a Cocaine Anonymous meeting, I had expected looks of disgust and fear, but instead I received warm hugs and appreciation. It’s a big change for someone who grew up being taunted and feeling rejected. People in the Fellowship value my individual experience, and many have thanked me for being honest about who I am."</i></p>
                                    <p>Many of us have lived our lives in a world of prejudice, bigotry and hatred just because we were lesbian, gay, bisexual, transgender or queer/questioning (LGBTQ+)—different from mainstream society. Some of us started using cocaine or other drugs out of a simple desire to feel accepted in a world that seemingly had no place for us. Perhaps it was as simple as finding ourselves swept up in the fast and decadent world of all-night clubs and circuit parties that we had discovered while we explored our sexual orientation. Others sought escape from the harsh judgment and rejection we felt from our families and friends every time we got drunk or high. For many of us, the only way we could deal with our feelings of being different was to numb ourselves with drugs and alcohol.</p>
                                    <p>Often we were shunned or rejected by our families. We were sometimes pushed aside or judged by religion, being told that God hated us and that our lifestyle would take us to Hell. Perhaps we even felt that God did hate us; that He had abandoned us. We believed that no one could possibly relate to our struggles or our lifestyles. We asked ourselves, “Can God help me, even though I’m not like everybody else? How can I possibly get sober?” It wasn’t enough that we had a disease which made us dependent upon mind-altering chemicals and made our lives unmanageable; we believed our social interaction—our entire style of life—was being judged by the rest of the world. Even worse, we often experienced self-loathing and judging of ourselves.</p>
                                    <p>Then we discovered Cocaine Anonymous and learned that the only requirement for membership was a desire to stop using cocaine and all other mind-altering substances. This was reassuring, yet many of us still felt separate from the rest of the group. We worried that our sexual orientation was so big a barrier that it would keep us from truly relating with our fellows in Cocaine Anonymous. Then we heard some of the other members share their experiences. As we listened, we related to the similarities; we realized we weren’t the only ones who feared we were “different.” We heard hope, faith and courage and began to believe that maybe we, too, could recover.</p>
                                    <p>When we first made it to a meeting of Cocaine Anonymous, we were offered a helping hand. Those who reached out didn’t care if we were male, female, rich, poor, what drugs we used, what religion we were, or what color our skin was. The fact that some of us were LGBTQ+ was not an obstacle; those carrying the message of recovery to us were far more focused on our need for sobriety than our sexual orientation. Their unconditional love and acceptance made us feel comfortable and welcomed.</p>
                                    <p><i>"When I first went to C.A., I was deeply in the closet, but I knew that I couldn’t keep it a secret from everyone. So I chose a person in the rooms I thought I could trust. It still took me a couple of weeks to open up, but when I did, I felt as if a giant load had been lifted off of my shoulders. My sponsor didn’t judge me or withdraw from me at all. In fact, my sponsor became one of my best friends and closest advisors."</i></p>
                                    <p>For many of us, admitting an addiction is similar to coming out as LGBTQ+: it can be scary to be open about something we have hidden or denied for so long. Like coming out, getting honest about addiction ultimately improves our lives and opens up possibilities we never knew existed. Being part of the LGBTQ+ community, we can often feel that we are much different from others. What we find in C.A. is that many people, from all walks of life, share these feelings of being different, not fitting in, and that these feelings play a role in our addiction. By seeing our similarities to others, even those whose experiences, lifestyles or choices are unlike our own, we can find a place where our sexual orientation and/or gender identities become a part of who we are, and not all we are . . . and not all we can be. We can see that our struggles with sex issues are not so different from anyone else’s.</p>
                                    <p>We worked the Twelve Steps and discovered the many joys of sobriety. We found the gift of feeling comfortable in our own skin and the courage to be ourselves. We discovered that it didn’t matter whether we were in or out of the closet. All that was necessary was to show up with a desire to be clean and sober. We can join in harmonious action with other addicts to stay in the solution.</p>
                                    <p>We cherish the feeling of belonging we’ve found in Cocaine Anonymous, so it is important—imperative—that we continue this cycle. We know how important it is to feel "a part of.” Our Fifth Tradition tells us that our primary purpose is to carry the message of recovery to the addict who still suffers and this is what we endeavor to do. No one should ever feel ostracized or judged in Cocaine Anonymous. We understand the pain and fear caused by the disease of addiction; we empathize with the new member’s reluctance and confusion because we have “been there.” We have felt “apart from” and we understand. We can honestly say, “We know who you are, and we know Cocaine Anonymous is the place for you, because it is the place for us."</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/LGBTQ-pamphlet.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default CAIsAlsoForTheLGTQPlusAddict
import React from 'react'

const GuideToThe12Steps = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > A Guide to the 12 Steps </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>This pamphlet contains a description of one method for taking the Twelve Steps of Cocaine Anonymous. To help us work the Twelve Steps, Cocaine Anonymous uses a text entitled Alcoholics Anonymous, commonly referred to as the “Big Book.” When studying this text, some of us find it useful to substitute the word “cocaine” for “alcohol” and the word “using” for “drinking,” although in the process, some of us discovered that we are alcoholics as well as addicts.</p>
                                    <p>Because some of our members believe there are ways to take the Steps other than the method described in the Big Book, we suggest that the reader seek guidance from a sponsor, an experienced C.A. member, or their Higher Power, to help them decide on the method that is right for them.</p>
                                    <p>This pamphlet is not a substitute for using the Big Book and a sponsor. Its purpose is to shed light on the Twelve-Step program in the Big Book of Alcoholics Anonymous, as it relates to our addiction.</p>
                                    <p>Taking the Twelve Steps prepares us to have a “spiritual awakening” or a “spiritual experience” (page 567* in Alcoholics Anonymous). These phrases refer to the change in our thinking, attitudes, and outlook that occurs after taking the Steps. This change frees us from active addiction.</p>
                                    <p>Applying the Steps in our daily lives enables us to establish and improve our conscious contact with God or our Higher Power. Many in our Fellowship believe that the greatest safeguard in preventing relapse lies in consistent application of the Twelve Steps.</p>
                                    <p>Newcomers often ask, “When should I take the Steps?” Page 34 of the Big Book states, “Some will be drunk [high] the day after making their resolutions [not to use again]; most of them within a few weeks.” The choice, ultimately, is up to the reader of this pamphlet, but a full understanding of Step One can often provide the willingness necessary to take the other eleven Steps.</p>
                                    <p><b>STEP ONE: We admitted we were powerless over cocaine and all other mind-altering substances—that our lives had become unmanageable.</b><br></br> Our powerlessness operates on three levels: (1) A physical allergy to cocaine, which makes it virtually impossible for us to stop using once we start; (2) A mental obsession, which makes it impossible to stay sober permanently on our own (pages 24 and 34); and (3) A spiritual malady, which separates us from our Higher Power’s ability to get and keep us sober.</p>
                                    <p>Many of us assumed that Step One meant we couldn’t get high anymore because we couldn’t handle using at all. In fact, it really means that barring divine intervention, we are unable to stay away from that first hit, line, or whatever (pages 24 and 34), and that we will use again and again, no matter how much we want to stay sober.</p>
                                    <p>The second part of Step One refers to how we are unable to manage our lives, even when we are sober. One example of this unmanageability is being “restless, irritable, and discontented” (page xxviii; other examples are found in the second full paragraph of page 52).</p>
                                    <p>Step One is the foundation of the entire Twelve-Step process. Without a full understanding of what this Step means to us personally, we can’t expect to make much progress on the other eleven Steps. For more information, study Dr. Bob’s experience on pages xvi and 155 (Dr. Bob was one of A.A.’s co-founders).</p>
                                    <p>Two useful questions for deciding whether we are really addicts are, “Can I stop permanently, if and when I want to?” and, “Can I control the amount I use once I start?” If the answer to either question is no, we probably are addicts, according to the Big Book.</p>
                                    <p><b>STEP TWO: Came to believe that a Power greater than ourselves could restore us to sanity.</b><br></br>When we understand Step One and are convinced that we are addicts (page 30), we are ready for Step Two. Coming to believe in a Higher Power’s ability to restore us to sanity does not require that we believe in God. All we need is an open mind and a willingness to believe that there is a Power greater than ourselves (pages 46 and 47).</p>
                                    <p>Many of us come to Cocaine Anonymous without any religious or spiritual experience, yet are able to make a start towards what the concept of a Higher Power might mean to us. Some of us use the C.A. group as a Higher Power until we can develop a concept of our own. Any concept, no matter how inadequate we believe it to be at the time, is enough to make a start with Step Two (page 46).</p>
                                    <p>The insanity referred to in Step Two is the part of our thinking that allows us to convince ourselves that we can successfully use again. Once this “mental obsession” takes hold, we are compelled to use over and over again, regardless of the consequences that we know will follow. It is this vicious cycle that helps us become willing to believe that perhaps a Power greater than ourselves can restore us to sanity (page 48). Being convinced of the “three pertinent ideas” (the a-b-c’s on page 60) brings us to Step Three.</p>
                                    <p><b>STEP THREE: Made a decision to turn our will and our lives over to the care of God as we understood Him.</b><br></br>In Step Three, we make a decision to turn our will and our lives over to the care of our concept of God at the time. The first requirement is becoming convinced that “any life run on self-will could hardly be a success” (page 60). That text illustrates the meaning of a life run on self-will by describing the behavior of an actor who wants to run the whole show. Many of us find it useful to substitute our own name in this passage and to ask ourselves honestly whether this scenario doesn’t sound similar to the way we are running our own lives (pages 60-62). The text further suggests that this kind of self-centeredness is “the root of our troubles” (page 62). After we understand what running our lives based on self-will means and acknowledge its futility, we are asked to do the “Third-Step Prayer” on page 63 (or its equivalent), before going on to Step Four.</p>
                                    <p><b>STEP FOUR: Made a searching and fearless moral inventory of ourselves.</b><br></br>In Step Four, we examine the wreckage that is accumulating from our attempts to run the show and the things that have been blocking us from our Higher Power. By completing and analyzing our inventory (page 70), we are able to see where our natural instincts for money, sex, power, and prestige have gone out of control, as we attempt to satisfy them in selfish and self-centered ways (page 62). The inventory involves looking at the people we resent (page 64-67), the things we are afraid of (pages 67-68), and the people we have harmed through our misconduct. Step Four enables us to discover, own, and begin to be freed from the “bondage of self” described in the Third-Step Prayer.</p>
                                    <p><b>STEP FIVE: Admitted to God, to ourselves, and to another human being the exact nature of our wrongs.</b><br></br>In Step Five, we share our Fourth-Step inventory with the person of our choice (usually our sponsor) and continue to discover “the exact nature of our wrongs.” By taking this Step, we are able to identify areas where we have allowed our selfishness, our instincts, and our fears to control us. Sharing our inventory allows another human being to help us examine problems that we are unable to understand by ourselves (page 72). After completing Step Five, it is suggested that we go home and review the first five Steps of the program and our inventory to see whether we need to add any resentments, fears, or persons we have harmed (page 75). We ask ourselves whether we have withheld anything in our inventory. Have we illuminated “every twist of character, every dark cranny of the past” (page 75)? If so, we are ready for Step Six.</p>
                                    <p><b>STEP SIX: Were entirely ready to have God remove all these defects of character.</b><br></br>In reviewing our “shortcomings,” we ask ourselves whether we find these defects of character undesirable and whether we believe God can remove them all. If we feel there are defects we’re not willing to let go of, the Big Book suggests that we pray for the willingness to have them removed (page 76).</p>
                                    <p><b>STEP SEVEN: Humbly asked Him to remove our shortcomings.</b><br></br>When Step Six is complete, we say the Seventh-Step Prayer to have our shortcomings removed by God as we understand God (page 76).</p>
                                    <p><b>STEP EIGHT: Made a list of all persons we had harmed, and became willing to make amends to them all.</b><br></br>In Step Eight, we list all the people we have harmed, and we pray for the willingness to make amends to them all. Most of the amends we need to make are disclosed in the resentment inventory (page 67) and our sexual inventory (pages 68-70). We also include anyone else we have harmed who isn’t listed in our Fourth-Step inventory.</p>
                                    <p><b>STEP NINE: Made direct amends to such people wherever possible, except when to do so would injure them or others.</b><br></br>In Step Nine, we make amends to the people we have harmed. The Big Book gives us examples for how to go about making these actual amends (pages 76-83). Counsel from one’s sponsor, as well as from others who’ve had experience applying this Step, is also helpful in showing us how to repair the damage we’ve caused in the past. It is through Step Nine that we’re freed from the guilt, fear, shame, and remorse that results from the harm we’ve done others. Taking this Step helps us “to fit ourselves to be of maximum service to God and the people about us” (page 77).</p>
                                    <p><b>STEP TEN: Continued to take personal inventory and when we were wrong promptly admitted it.</b><br></br>Having taken the first eight Steps and made a beginning on Step Nine, we find ourselves at Steps Ten, Eleven and Twelve. Although the Twelve Steps are designed to be taken in order, it is suggested that we take Steps Ten, Eleven and Twelve on a daily basis while making our Ninth-Step amends.</p>
                                    <p>The last three Steps encompass much of the first nine Steps in their structure and application. Step Ten involves continuing to take personal inventory and setting right any new wrongs as we go along. The Big Book teaches us that when our shortcomings “crop up,” we deal with them by using Step Ten (page 84). The main purpose of Step Ten is to prevent us from being blocked off again from God, whose power ultimately keeps us sober (page 64).</p>
                                    <p><b>STEP ELEVEN: Sought through prayer and meditation to improve our conscious contact with God as we understood Him, praying only for knowledge of His will for us and the power to carry that out.</b><br></br>There are many definitions of prayer and meditation, and a detailed discussion is not practical within the confines of this pamphlet. Some basic suggestions, on pages 86-88 of the text, outline a daily and nightly routine we can apply to allow God to monitor and direct our thinking.</p>
                                    <p><b>STEP TWELVE: Having had a spiritual awakening as the result of these Steps, we tried to carry this message to addicts, and to practice these principles in all our affairs.</b><br></br>Having taken the first eleven Steps, we are now at Step Twelve and are ready to carry the message to other addicts (pages 89-103). Every time we work with another addict we are reminded just how bad it was when we first came into the program. In the newcomer, we recognize the same trembling hands, weight loss, and look of desperation and sheer terror that we had. We hear the unmanageability in terms of depression, misery, and unhappiness, whether openly expressed or feebly concealed. We are reminded of our own past troubles with personal relationships as we see newcomers struggle with theirs. Finally, our faith in God’s ability to restore us to sanity is reinforced, as we see God transform the life of a newcomer, right before our eyes.</p>
                                    <p>In addition to carrying the message to other addicts, Step Twelve involves practicing these principles in all areas of our lives. If addicts who relapse are fortunate enough to return to the program and analyze what happened, they may find they had stopped practicing these principles in all their affairs—that they were no longer examining their motives, reviewing their days, praying, or carrying the message (pages 15 and 89).</p>
                                    <p><b>If there were one watchword to describe how these Steps should be practiced, it would be “continuously,” for it is only through God and constant application of these principles that we can be assured of the recovery offered by Cocaine Anonymous.</b></p>
                                    <p><i>The above excerpts from Alcoholics Anonymous are reprinted with permission of Alcoholics Anonymous World Services, Inc. (“AAWS”). Permission to use these excerpts does not mean that AAWS has reviewed or approved the contents of this publication, or that AAWS necessarily agrees with the views expressed herein. A.A. is a program of recovery from alcoholism only—use of this material in connection with programs and activities which are patterned after A.A., but which address other problems or concerns, or in any other non-A.A. context, does not imply otherwise.</i></p>
                                    <p><i>*Page numbers in this pamphlet refer to the 4th edition of Alcoholics Anonymous.</i></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/A-Guide-to-the-12-Steps.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default GuideToThe12Steps
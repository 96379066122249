import React from 'react'

const CocaineAnonymousSelfTest = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Cocaine Anonymous Self-Test </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>It is the collective experience of the members of Cocaine Anonymous that addiction is a problem not limited to cocaine or any other mind-altering substance. With this in mind, we suggest you answer the following questions:</b></p>
                                    <p><ol>
                                        <li>Have you ever used more cocaine, alcohol or other drugs than you planned?</li>
                                        <li>Has the use of cocaine, alcohol or other drugs ever interfered with your job?</li>
                                        <li>Is your use of cocaine, alcohol or other drugs causing problems within your relationships?</li>
                                        <li>Do you ever feel depressed, guilty, or remorseful after using cocaine, alcohol or other drugs?</li>
                                        <li>Do you use whatever cocaine, alcohol or other drugs you have, almost continually, until the supply is exhausted?</li>
                                        <li>Have you ever experienced physical problems due to your use of cocaine, alcohol or other drugs?</li>
                                        <li>Do you ever regret using cocaine, alcohol or other drugs for the first time?</li>
                                        <li>Do you ever obsess about getting cocaine, alcohol or other drugs when you do not have any?</li>
                                        <li>Are you experiencing financial difficulties due to your use of cocaine, alcohol or other drugs?</li>
                                        <li>Do you experience an anticipation high when you are about to use cocaine, alcohol or other drugs?</li>
                                        <li>Do you have difficulty sleeping without alcohol or other drugs?</li>
                                        <li>Are you absorbed with the thought of using cocaine, alcohol or other drugs even while interacting with a friend or loved one?</li>
                                        <li>Have you begun to use cocaine, alcohol or other drugs while you’re alone?</li>
                                        <li>While using or drinking, do you ever have feelings that people are talking about you or watching you?</li>
                                        <li>Do you have to use more cocaine, alcohol or other drugs to get the same effects you once experienced?</li>
                                        <li>Have you tried to cut down on your use of cocaine, alcohol or other drugs only to find that you could not?</li>
                                        <li>Have you tried to stop using cocaine, alcohol or other drugs only to find that you could not stay stopped?</li>
                                        <li>Have any of your friends or family suggested that you may have a problem with cocaine, alcohol or other drugs?</li>
                                        <li>Have you ever lied to or misled people about how much cocaine, alcohol or other drugs you use?</li>
                                        <li>Have you ever lied to or misled people about how often you get high or drunk?</li>
                                        <li>Do you use cocaine, alcohol or other drugs in your car, at work, in the bathroom, or in other public places?</li>
                                        <li>Are you afraid that if you stop using cocaine, alcohol or other drugs, your work will suffer or you will not be able to function?</li>
                                        <li>Do you spend time around people or go places you would normally stay away from if not for the availability of cocaine, alcohol or other drugs?</li>
                                        <li>Have you ever stolen money, cocaine, alcohol, or other drugs from friends or family?</li>
                                        <li>Has using and/or drinking cost you more than money?</li>
                                    </ol></p>
                                    <p>If you have answered “yes” to any of these questions, you may have a problem. There is an answer: Come to meetings of Cocaine Anonymous. While the name “Cocaine Anonymous” may sound drug-specific, we wish to assure you that our program is not. Many of our members did a lot of cocaine; others used only a little, and some never even tried coke. Whether we focused on a specific mind-altering substance or used whatever we could get our hands on, we had one thing in common: eventually we all reached a point where we could not stop. Over time, virtually every single one of us has realized that our real problem is not cocaine or any specific drug; it is the disease of addiction.</p>
                                    <p>According to C.A.’s Third Tradition, the only requirement for membership is a desire to stop using cocaine and all other mind-altering substances. It doesn’t matter to us if you drank or what type of drugs you used; if you have a desire to stop, you are welcome in Cocaine Anonymous!</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/CA-Self-Test.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default CocaineAnonymousSelfTest
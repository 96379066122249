import React from 'react'

const BeingAGSR = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Being a GSR </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>Welcome to the service community of Cocaine Anonymous! Many of our members have found fulfillment and serenity by allowing their Higher Power to work through them in service to our Fellowship.</p>
                                    <p>Most of our trusted servants began their journey at the Group level by doing things like greeting members at the door, making coffee, setting up chairs, or cleaning up after the meeting. Through these commitments, we began to see the value of service and how our actions can benefit others. We also experienced a new source of humility, joy, and unity, often making lifelong friends in the process. Over time, we demonstrated our trustworthiness and increasingly selfless commitment to our fellows and our Home Group. We then became ready for a new level of trusted service to the Fellowship.</p>
                                    <p>One of the most important service positions to which a member can be elected is the Group Service Representative, or GSR. The majority of our future trusted servants at the District, Area, and even World Service levels will have served as GSRs. Groups should take great care with GSR selection, as the quality of the District/Area service bodies and ultimately World Services can only be as good as the choices the individual Groups make.</p>
                                    <p>The main purpose of the GSR is to provide two-way communication between the Group and the District or Area, depending upon the local service structure. The GSR should also be trusted with the Group’s vote. Although a GSR does not require Group approval to vote on matters affecting the Group or C.A. as a whole, it is the responsibility of the GSR to vote being mindful of the group conscience (see Concept Three, the traditional “Right of Decision”).*</p>
                                    <p>There are some important considerations prior to accepting a nomination to the GSR position or any other service commitment. It is suggested to first consult with one’s sponsor, seeking an objective and honest appraisal of one’s readiness and qualifications. A nominee should have a working knowledge of the Twelve Steps and the Twelve Traditions. It is also suggested to become familiar with the C.A. World Service Manual,* particularly the Twelve Concepts of World Service and Robert’s Rules of Order as well as any local service guidelines. A nominee should also be fully aware of and consider the level of commitment and sacrifice of time involved.</p>
                                    <p>GSR responsibilities typically include the following:</p>
                                    <p>
                                        <ul>
                                            <li>Active membership in the Home Group.</li>
                                            <li>Attending Home Group business meetings. In Groups without a separate business meeting chair, the GSR usually chairs the business meeting.</li>
                                            <li>
                                                Attending their District/Area meetings and Assemblies, paying close attention to topics of specific interest that are vital to facilitating unity between the Group and the rest of the Fellowship. These topics include but are not limited to:
                                                <ul>
                                                    <li>Fundraisers and events;</li>
                                                    <li>Service position vacancies and nominations;</li>
                                                    <li>Tabled or passed motions; and</li>
                                                    <li>Any subject on which Group input is sought.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>
                                    <p>To convey this information effectively, it is suggested that the GSR take notes at all business meetings, obtain minutes of previous District/Area meetings, and bring any available flyers back to the Group for distribution.</p>
                                    <p>
                                        <ul>
                                            <li>Keeping the Group informed about World Service Delegate activities.</li>
                                            <li>Making announcements during regular meetings of the Home Group and during business meetings about things happening in the District or Area.</li>
                                            <li>Seeking input from the Home Group conscience regarding elections or any other matters upon which the GSR anticipates casting a vote at District and/or Area meetings.</li>
                                            <li>Bringing any concerns, ideas, feedback, etc., that the Home Group may have to District and/or Area meetings.</li>
                                            <li>Keeping the Alternate GSR informed in case he or she needs to fill in for the GSR.</li>
                                            <li>Seeing that the Traditions are followed in the Home Group and encouraging regular group inventories.</li>
                                        </ul>
                                    </p>
                                    <p>For many of us, serving as GSR was a vital step to delving deeper into our Fellowship and our personal recovery. Every represented voice of the Group informs the collective conscience of C.A. In keeping with the Eleventh Tradition, the GSR fulfills his or her service position with enthusiasm and radiates that spirit within the Group, serving by example!</p>
                                    <p>*The C.A. World Service Manual (WSM) is cited herein for specific additional information, but it also provides valuable guidance regarding all levels of C.A. service. The WSM can be found in the Service section of the C.A. World Services website, www.ca.org. The Twelve Steps and Twelve Traditions (the “Twelve and Twelve”) and The A.A. Service Manual, Combined With the Twelve Concepts for World Service offer additional guidance regarding the Traditions and Concepts. Although these two Alcoholics Anonymous publications are not C.A. World Service Conference-approved literature, it is the opinion of the Conference that they are also valuable tools to support C.A. members in their recovery. For clarification, please refer to the Advisory Opinions referenced in the section of the WSM dealing with approved literature. In the spirit of Tradition Six, C.A. is not allied with any sect, denomination, politics, organization or institution.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Being-a-GSR.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default BeingAGSR
import React from 'react';

const HandI = () => (
  <section className="section">
    <div className="container" >
      <h1 className="title">Hospitals &amp; Institutions </h1>
      <div className="box has-background-white content">
      <div className="columns">
        <div className="column">
          <p className="subtitle">A New High From H&amp;I </p>
          <div className="columns">
            <div className="column is-one-third">
              <img alt="talk" src="/img/pic4_1.jpg"></img>
            </div>
            <div className="column">
              <p>
                Our primary purpose is to carry the message of recovery to the addict who still suffers. One way we do this is by holding meetings in Hospitals and Institutions for people who are not able to attend outside meetings.
                </p>
            </div>
          </div>
          <p>
            Institutions served include correctional facilities, sanitariums, detox units, juvenile detention centers, half-way houses and shelters; either governmental or private. Confinement may be voluntary or involuntary.
            </p>
          <p>
            Through working with others in H &amp; I's, members of Cocaine Anonymous share their experience, strength and hope.
            </p>
        </div>
        <div className="column">
          <p className="subtitle">Do you have an H&amp;I commitment?</p>
          <p>
            CANY has a long tradition of carrying the message to the still sick and suffering through H&amp;I commitments - and you can help!
            </p>
          <p>
            Bringing the message to those who are not able to attend meetings is a vital part of our own recovery. CA members of all walks of life are invited to participate. If you have 6 months or more, you can co-chair a meeting. If you have 90 days or more, you can speak at a meeting. Even our friends who are counting days have things they can do to help give back what is so freely given to them.
            </p>
        </div>
      </div>
    </div >
    </div>
  </section>
)

export default HandI;
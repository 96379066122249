import React from 'react'

const WhoIsACAMember = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Who Is A C.A. Member? </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                            While the name “Cocaine Anonymous” may sound drug-specific, we wish to assure you
                            that our program is not. Many of our members did a lot of cocaine; others used only a
                            little, and some never even tried coke. We have members who drank only on occasion,
                            those who casually referred to themselves as drunks, and others who were full-blown
                            alcoholics. Lots of us used a wide variety of mind-altering substances. Whether we
                            focused on a specific substance or used whatever we could get our hands on, we had
                            one thing in common: eventually we all reached a point where we could not stop.</p>
                        <p>
                            According to C.A.’s Third Tradition, the only requirement for membership is a desire to
                            stop using cocaine and all other mind-altering substances. <strong><i>Whatever you may have
                            been using, if it led you to this meeting, you’re probably in the right place.</i></strong> Over
                            time, virtually every single one of us has realized that our real problem is not cocaine or
                            any specific drug; it is the disease of addiction.
                        </p>
                        <p>
                            It can be tempting to focus on our differences rather than our similarities, but this can
                            blind us to potential sources of support in our recovery. As we hear other members’
                            stories, the most important question to ask ourselves is not, “Would I have partied with
                            these people?” but rather, “Do these people have a solution that can help me stay
                            sober?” We encourage you to stick around and listen with an open mind.
                        </p>
                        <p>
                            With its all-inclusive Third Tradition and First Step, Cocaine Anonymous welcomes
                            anyone with a drug or alcohol problem and offers a solution. C.A.’s Twelve Steps are
                            not drug-specific, and Cocaine Anonymous is not a drug-specific Fellowship. It doesn’t
                            matter to us if you drank or what type of drugs you used; if you have a desire to stop,
                            you are welcome here!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default WhoIsACAMember
import React from 'react'

const WeCanRecover = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > We Can Recover </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                            Welcome to Cocaine Anonymous. We are all here for the same reason—our inability to
                            stop using cocaine and all other mind-altering substances. The first step towards solving
                            any problem is admitting that there is a problem.          </p>
                        <p>
                            The problem, as we see it, consists of an obsession of the mind and an allergy of the
                            body. The obsession is a continued and irresistible thought of cocaine and the next
                            high. The allergy creates an absolute inability to stop using once we begin.          </p>
                        <p>
                            We wish to assure you that there <i>is</i> a solution and that recovery <i>is</i> possible. It begins
                            with abstinence and continues with practicing the Twelve Steps of recovery, one day at
                            a time. Our program, the Twelve Steps of Cocaine Anonymous, is the means by which
                            we move from the problem of drug addiction to the solution of recovery.          </p>
                        <p>
                        <ol type="1">
                            <li>We admitted we were powerless over cocaine and all other mind-altering
                            substances—that our lives had become unmanageable.</li>
                            <li>Came to believe that a Power greater than ourselves could restore us to
                            sanity.</li>
                            <li>Made a decision to turn our will and our lives over to the care of God <i>as we
                            understood Him</i>.</li>
                            <li>Made a searching and fearless moral inventory of ourselves.</li>
                            <li>Admitted to God, to ourselves, and to another human being the exact nature
                            of our wrongs.</li>
                            <li>Were entirely ready to have God remove all these defects of character.</li>
                            <li>Humbly asked Him to remove our shortcomings.</li>
                            <li>Made a list of all persons we had harmed, and became willing to make
                            amends to them all.</li>
                            <li>Made direct amends to such people wherever possible, except when to do so
                            would injure them or others.</li>
                            <li>Continued to take personal inventory and when we were wrong promptly
                            admitted it.</li>
                            <li>Sought through prayer and meditation to improve our conscious contact with
                            God <i>as we understood Him</i>, praying only for knowledge of His will for us and
                            the power to carry that out.</li>
                            <li>Having had a spiritual awakening as the result of these Steps, we tried to
                            carry this message to addicts, and to practice these principles in all our
                            affairs.</li>
                        </ol>
                        </p>
                        <p>
                            Cocaine Anonymous is a spiritual program, not a religious one. In C.A. we believe each
                            individual can choose a Higher Power of his or her own. In short, a God of his or her
                            own understanding.
                        </p>
                        <p>
                            No one comes into Cocaine Anonymous to find God. We came into these rooms to get
                            rid of a terrifying drug habit. Look around this room. You are surrounded by people who
                            came as a last resort. We came into these rooms emotionally, financially and spiritually
                            bankrupt. We have experienced all sorts of tragedies as a result of cocaine, drugs
                            and/or alcohol. We have lived many of the same horrors you have, yet today we are
                            free from the misery, terror and pain of addiction.
                        </p>
                        <p>
                            Maybe some of us were worse off than you; maybe some of us didn’t hit as low a
                            bottom as you. Still the fact remains that those of us who are recovering have come to
                            believe that a Higher Power of our own understanding can restore us to sanity.
                        </p>
                        <p>There <i>is</i> a solution; we can recover from addiction. One day at a time, it is possible to
                            live a life filled with hope, faith and courage.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default WeCanRecover
import React from 'react'

const TooYoungToRecover = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Too Young to Recover? </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>When we walked into Cocaine Anonymous, we looked around the room and began to wonder if we were too young to get clean and sober. Many of us were too young to have driver’s licenses, to vote, to go into bars, or even have jobs. Surely we were too young to be addicts! Our denial told us that maybe our using and drinking was just a phase.</p>
                                    <p>Soon after coming into Cocaine Anonymous, we realized that although we might not have used as long, we related to other members’ experiences. We too had lost relationships with our families and friends, stopped doing things we once loved, stopped going to school or work, and hated who we had become. We shared the shame and embarrassment of using. We realized, like other members, that we had abandoned our morals and values for another hit, fix, pill, or drink.</p>
                                    <p>Occasionally, we found ourselves in C.A. meetings, looking for a solution, not feeling welcomed. When this happened, it was important for us to remember C.A.’s Third Tradition: “The only requirement for C.A. membership is a desire to stop using cocaine and all other mind-altering substances.” It truly did not matter how old we were; all that mattered was what we wanted to do about our using. We found it was necessary that no matter what, we too showed up and attended C.A. meetings. Each of us needed to find a sponsor who could take us through the 12 Steps, so we could learn to apply the Steps and principles to our life.</p>
                                    <p>Many of us were still in school or had already dropped out when we came into C.A. Our experience showed it was best to change the people we hung around with. Sometimes it was difficult to see our old friends in class, wondering what we might be missing out on. Because of this, we realized how important it was for us to use the tools of recovery to build new friendships and to learn how to have fun again. We learned how to have clean and sober fun by having coffee, going to sober dances, movies, bowling, and attending other C.A. events.</p>
                                    <p>After attending meetings and working with a sponsor on the 12 Steps of C.A., we found that it was vital to carry our message to the still-suffering addict, no matter what age. We walked into C.A. with many questions, but have found that no one is too young to recover in Cocaine Anonymous.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Too-Young-to-Recover.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default TooYoungToRecover
import React from 'react'

const Anonymity = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Anonymity </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>If you have ever questioned the meaning of anonymity, perhaps this pamphlet will help.</p>
                                    <p>One definition of “anonymous” is “nameless.” Anonymity is vital to the continued growth and existence of Cocaine Anonymous, and the Fellowship’s name contains an implicit promise of privacy. By treating who we see and what we hear at our meetings as confidential, C.A. remains a safe place to share our experience, strength and hope.</p>
                                    <p>Privacy is of the utmost importance when we first enter the Fellowship. We are terrified at the thought that family, friends or work colleagues might find out about our addiction. As we grow in our sobriety, anonymity takes on a greater spiritual emphasis. It calls on us to act with humility; that is, to be right-sized in our opinions of ourselves and our relationship with others.</p>
                                    <p><b>AS EXPRESSED IN OUR TRADITIONS</b><br></br>C.A.’s Eleventh Tradition states, “Our public relations policy is based on attraction rather than promotion; we need always maintain personal anonymity at the level of press, radio, television and films.”</p>
                                    <p>This Tradition suggests that no one among us act as spokesperson for our Fellowship. If we do carry out service work at the public level, we do so without making our name known. This helps protect both the Fellowship and us. It protects the Fellowship from being tarnished by behavior we may engage in and protects us from grandiosity, which would harm our own recovery.</p>
                                    <p>We are not a secret society. On a personal level, we share our experience with those who may benefit from what we have found. Rather than promoting our solution to drug and alcohol addiction, it is our aim that when prospective fellows see how our lives have turned around, they will be attracted and inspired to join us.</p>
                                    <p>Within our Fellowship, it is the choice of each individual whether to disclose his or her last name or share contact information. If we recognize other C.A. members outside of our meetings, care should be taken to avoid inadvertently breaking their anonymity in front of any non-members present.</p>
                                    <p>The Twelfth Tradition of Cocaine Anonymous states: “Anonymity is the spiritual foundation of all our Traditions, ever reminding us to place principles before personalities.”</p>
                                    <p>The spiritual principle of anonymity is humility. As we practice the 12 Steps of Cocaine Anonymous, self-esteem grows and we become comfortable with ourselves. We are no longer overly concerned about how others view us. We are able to apply the principles of honesty, unselfishness, purity of motive and love in our dealings with others, irrespective of who they are. The application of humility affords us balanced judgment and leads us to a better future.</p>
                                    <p><b>AS DISCUSSED IN THE C.A. WORLD SERVICE MANUAL</b><br></br>Traditionally, C.A. members have always taken care to preserve their anonymity at the public level: press, radio, television and films. We know from experience that many people with drug problems might hesitate to turn to C.A. for help if they thought their problems might be discussed publicly, even inadvertently, by others. Newcomers should be able to seek help with complete assurance that their identities will not be disclosed to anyone outside the Fellowship.</p>
                                    <p>We believe that the concept of personal anonymity has a spiritual significance for us: it discourages the drives for personal recognition, power, prestige or profit that have caused difficulties in some societies. Much of our relative effectiveness in working with other addicts might be impaired if we sought or accepted public recognition.</p>
                                    <p>While each member of C.A. is free to make his or her own interpretation of C.A. Traditions, no individual is ever recognized as a spokesperson for the Fellowship locally, nationally or internationally. Each member speaks only for themselves.</p>
                                    <p>Cocaine Anonymous is grateful to all media for their assistance in strengthening and observing our Tradition of anonymity. Periodically, the C.A. World Service Office sends to all major media a letter describing the Tradition and asking their support in observing it.</p>
                                    <p>A C.A. member may, for various reasons, “break anonymity” deliberately at the public level. Since that is a matter of individual choice and conscience, the Fellowship as a whole has no control over such deviations from Tradition. It is clear, however, that they do not have the approval of the group conscience of C.A. members.</p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Anonymity.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Anonymity
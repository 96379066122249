import React from 'react'

const WhatIsCA = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > What is C.A.? </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p><b>Cocaine Anonymous is a Fellowship of men and women who share their experience, strength and hope with each other that they may solve their common problem and help others recover from their addiction.</b><br></br>The best way to reach someone is to speak to them on a common level. The members of C.A. are all recovering addicts who maintain their individual sobriety by working with others. We come from various social, ethnic, economic and religious backgrounds, but what we have in common is addiction.</p>
                                    <p><b>The only requirement for membership is a desire to stop using cocaine and all other mind-altering substances.</b><br></br>Anyone who wants to stop using cocaine and all other mind-altering substances (including alcohol and other drugs) is welcome.</p>
                                    <p><b>There are no dues or fees for membership; we are fully self-supporting through our own contributions.</b><br></br>We do ask for voluntary contributions at meetings to cover expenses such as coffee, rent, literature and services to help those who are still suffering. However, newcomers need not feel obligated to contribute. We do not accept donations from organizations or individuals outside the Fellowship.</p>
                                    <p><b>We are not allied with any sect, denomination, politics, organization or institution.</b><br></br>In order to maintain our integrity and avoid any possible complications, we are not affiliated with any outside organization. Although C.A. is a spiritual program, we do not align ourselves with any religion. Our members are free to define their spirituality as they see fit. Our individual members may have opinions of their own, but C.A. as a whole has no opinion on outside issues. We are not affiliated with any rehabs, recovery houses or hospitals, but many do refer their patients to Cocaine Anonymous to maintain their sobriety.</p>
                                    <p><b>Our primary purpose is to stay free from cocaine and all other mind-altering substances and to help others achieve the same freedom.</b><br></br>The only purpose of Cocaine Anonymous is to offer recovery to individuals who are suffering from addiction. Our experience has shown that the most effective way to attain and maintain sobriety is to work with others suffering from the same malady.</p>
                                    <p><b>We use the Twelve-Step recovery program because it has already been proven that the Twelve-Step recovery program works.</b><br></br>The Steps of C.A. are adapted from the original Twelve Steps of Alcoholics Anonymous. The Steps of Cocaine Anonymous read:</p>
                                    <p>
                                        <ol>
                                            <li>We admitted we were powerless over cocaine and all other mind-altering substances—that our lives had become unmanageable.</li>
                                            <li>Came to believe that a Power greater than ourselves could restore us to sanity.</li>
                                            <li>Made a decision to turn our will and our lives over to the care of God <i>as we understood Him</i>.</li>
                                            <li>Made a searching and fearless moral inventory of ourselves.</li>
                                            <li>Admitted to God, to ourselves, and to another human being the exact nature of our wrongs.</li>
                                            <li>Were entirely ready to have God remove all these defects of character.</li>
                                            <li>Humbly asked Him to remove our shortcomings.</li>
                                            <li>Made a list of all persons we had harmed, and became willing to make amends to them all.</li>
                                            <li>Made direct amends to such people wherever possible, except when to do so would injure them or others.</li>
                                            <li>Continued to take personal inventory and when we were wrong promptly admitted it.</li>
                                            <li>Sought through prayer and meditation to improve our conscious contact with God <i>as we understood Him</i>, praying only for knowledge of His will for us and the power to carry that out.</li>
                                            <li>Having had a spiritual awakening as the result of these Steps, we tried to carry this message to addicts, and to practice these principles in all our affairs.*</li>
                                        </ol>
                                    </p>
                                    <p><i>Additional literature is available for more information on C.A.’s Twelve Steps.</i></p>
                                    <p><b>PREAMBLE</b><br></br>Cocaine Anonymous is a Fellowship of men and women who share their experience, strength and hope with each other that they may solve their common problem and help others recover from their addiction. The only requirement for membership is a desire to stop using cocaine and all other mind-altering substances. There are no dues or fees for membership; we are fully Self-supporting through our own contributions. We are not allied with any sect, denomination, politics, organization, or institution. We do not wish to engage in any controversy and we neither endorse nor oppose any causes. Our primary purpose is to stay free from cocaine and all other mind-altering substances, and to help others achieve the same freedom.**</p>
                                    <p>We use the Twelve Steps of Recovery because it has already been proven that the Twelve-Step Recovery Program works.</p>
                                    <p><i>*The Twelve Steps are reprinted with permission of Alcoholics Anonymous World Services, Inc. Permission to reprint and adapt the Twelve Steps does not mean that A.A. is affiliated with this program. A.A. is a program of recovery from alcoholism. Use of the Steps in connection with programs and activities which are patterned after A.A. but which address other problems does not imply otherwise. THE TWELVE STEPS OF ALCOHOLICS ANONYMOUS: 1. We admitted we were powerless over alcohol—that our lives had become unmanageable. 2. Came to believe that a Power greater than ourselves could restore us to sanity. 3. Made a decision to turn our will and our lives over to the care of God as we understood Him. 4. Made a searching and fearless moral inventory of ourselves. 5. Admitted to God, to ourselves, and to another human being the exact nature of our wrongs. 6. Were entirely ready to have God remove all these defects of character. 7. Humbly asked Him to remove our shortcomings. 8. Made a list of all persons we had harmed, and became willing to make amends to them all. 9. Made direct amends to such people wherever possible, except when to do so would injure them or others. 10. Continued to take personal inventory and when we were wrong promptly admitted it. 11. Sought through prayer and meditation to improve our conscious contact with God as we understood Him, praying only for knowledge of His will for us and the power to carry that out. 12. Having had a spiritual awakening as the result of these Steps, we tried to carry this message to alcoholics, and to practice these principles in all our affairs.</i></p>
                                    <p><i>**Adapted with permission of the A.A. Grapevine, Inc.</i></p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/What-is-CA.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default WhatIsCA
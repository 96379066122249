import React from "react";
import { Link } from "react-router-dom";
import find from "lodash/find";
import Modal from "../Modal/Modal";
import FormatMeetingTime from '../../utils/FormatMeetingTime';
import "./Meeting.css";

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = 'pk.eyJ1IjoiY2FuZXd5b3JrIiwiYSI6ImNrcGFvZGE0ZjByYTIyeW11cnQ1cXI1cDAifQ.gsyQSF1xc4IIItJJ8obXGw';

class Meeting extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      meeting: null,
      meetings: [],
      lng: -70.9,
      lat: 42.35,
      zoom: 14,
      mapContainer: React.createRef()
    };    
  }

  _setModal = (img, type, e) => {
    e.preventDefault();
    this.setState({
      modalType: type,
      modalContent: img
    });
    this._toggleModal();
  };

  _toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalShow;
      return { modalShow: newState };
    });
  };

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  }

  _getMeeting = (meetings, slug) => {
    let meeting = find(meetings, function (meeting) {
      return meeting.slug === slug;
    });
    return meeting;
  };

  _preCOVID = meeting => {
    if (meeting.Location !== 'Online') {
      return 'Pre-COVID: ' + meeting.Location + ' ' + meeting.Address;
    }
  };

  _getDirections = (meeting) => {

    let formatted_address = '';

    if (meeting.city) {
      formatted_address = meeting.city;
      if (meeting.address) {
        formatted_address =
          meeting.address + ', ' + formatted_address;
      }
      if (meeting.state) {
        formatted_address =
          formatted_address + ', ' + meeting.state;
      }
      if (meeting.postal_code) {
        formatted_address =
          formatted_address + ' ' + meeting.postal_code;
      }
      if (meeting.country) {
        formatted_address =
          formatted_address + ', ' + meeting.country;
      }
    }

    return `${
      'https://www.google.com/maps'
    }?daddr=${meeting.latitude},${meeting.longitude}&saddr=Current+Location&q=${encodeURI(
      formatted_address
    )}`;

  }

  

  componentDidMount() {

    fetch('/data/meetings-data.json'
      , {
        headers: {
          'Context-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => {
        return response.json()
      })
      .then(response => {

        let meeting_param = window.location.pathname.replace(/_/g, ' ').toLowerCase();
        //remove /meetings/
        meeting_param = meeting_param.replace('/meetings/', '');

        let _meeting = this._getMeeting(response, meeting_param);
        if (_meeting.status !== 'virtual') {
          this.setState({
            meeting: _meeting,
            lng: _meeting.longitude,
            lat: _meeting.latitude
          });
          const { lng, lat, zoom } = this.state;
          new mapboxgl.Map({
            container: this.state.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom
          });
        } else
          this.setState({
            meeting: _meeting
          });

      });

    window.scrollTo(0, 0);
  }

  _getMeetingStatus = status => {
    let _status = '';

    if (status === 'hybrid') {
      _status = 'Hybrid meeting';
    } else if (status === 'virtual') {
      _status = 'Online meeting';
    }
    else {
      _status = 'In Person meeting';
    }

    return _status;
  }

  _getMeetingTypes = meeting => {

    const meetingTypes = {
      "BE": 'Beginner',
      "BB": 'Big Book',
      "CD": 'Closed Discussion',
      "M": 'Men Only',
      "W": 'Women Only',
      "OD": 'Open Discussion',
      "S": 'Step',
      "T": 'Topic',
      "CP": 'Candlelight Promises',
      "TC": 'Location Temporary Closed',
      "LGBTQIA+": 'Lesbian, gay, bisexual, and transgender'
    }

    function returnTypeMapping(type) {
      let _type = type.replace(/['"]+/g, '');
      return meetingTypes[_type];
    }

    if (meeting.types && meeting.types.length > 0) {
      return meeting.types.map((type, i) => (
        <li key={i}>{returnTypeMapping(type)}</li>
      ))
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.meeting &&
          <div className="container">
            <div className="columns">
              <div className="column">
                <h1 className="title pt-2"> {this.state.meeting.name} </h1>
                <p className="divide meeting">
                  <span className="icon-text">
                    <span className="icon">
                      <i className="fas fa-arrow-left"></i>
                    </span>
                    <span></span>
                  </span>
                  <Link to="/meetings">Back to Meetings</Link></p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="card mb-5">
                  {this.state.meeting.status !== 'virtual' ?
                    <div className="card-content">
                      <a href="0" onClick={(e) => this._setModal(this._getDirections(this.state.meeting), 'leaving', e)}>
                      <button className="button is-fullwidth is-success">
                        <span className="icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                        <span>Get Directions</span>
                      </button>
                      </a>
                    </div>
                    : ''}
                  <div className="card-content meeting">
                    <p className="subtitle">Meeting Information</p>
                    <p className="">
                      {FormatMeetingTime(this.state.meeting.day, this.state.meeting.time)}
                    </p>
                    <p>
                      <ul className="ml-5">
                        {this._getMeetingTypes(this.state.meeting)}
                      </ul>
                    </p>
                    <p className="pt-5">
                      {this._getMeetingStatus(this.state.meeting.status)}
                    </p>
                    <p className="pt-5">Region : {this.state.meeting.region}</p>
                  </div>
                  {this.state.meeting.status !== 'virtual' ?
                  <div className="card-content">
                    Location: <br></br>
                    {this.state.meeting.location} <br />
                    {this.state.meeting.address} <br />
                    {this.state.meeting.city} {this.state.meeting.postal_code}
                  </div>
                  : null}
                  {this.state.meeting.status !== 'physical' ?
                    <div className="card-content">
                      <a href="0" onClick={(e) => this._setModal(this.state.meeting.conference_url, 'leaving', e)}>
                      <button className="button is-info">
                        <span className="icon">
                          <i className="fas fa-video"></i>
                        </span>
                        <span>Zoom</span>
                      </button>
                      </a>
                      <p className="pt-4">
                        {this.state.meeting.conference_url_notes}
                      </p>
                    </div>
                    : 
                    <div className="card-content">
                      <div>{this.state.meeting.Note}</div>
                    </div>
                    }
                </div>
              </div>
              {this.state.meeting.status !== 'virtual' ?
              <div className="column">
                <div ref={this.state.mapContainer} className="map-container" />
              </div>
              : null}
            </div>
          </div>
        }
        <Modal
          type={this.state.modalType}
          content={this.state.modalContent}
          closeModal={this._toggleModal}
          openLink={this._openLink}
          modalState={this.state.modalShow}
        />
      </React.Fragment>
    )
  }
}

export default (Meeting);

import React from 'react'

const SerenityPrayer = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > The Serenity Prayer </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                            God,<br></br>
                            Grant me the serenity<br></br>
                            to accept the things I cannot change,<br></br>
                            the courage to change the things I can,<br></br>
                            and the wisdom to know the difference.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default SerenityPrayer
import React from 'react'

const Unity = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > Unity </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <article className="media">
                            <div className="media-content">
                                <div className="content">
                                    <p>Unity is a common bond that transcends all differences. We’ve discovered no matter how different our circumstances or the paths that brought us here, we all suffer from the same disease: addiction.</p>
                                    <p>We admitted our lack of power and accepted that we could not recover alone. The strength and direction of our recovery is found in our unity.</p>
                                    <p>We are people who might never have mixed. Addiction and recovery are the threads that bind us. We relate to such feelings as grandiosity, insecurity, jealousy, and false pride and are reminded we are all alike. By sharing our experience with other addicts and working the Twelve Steps of C.A., we come to know humility, security, acceptance, and self-worth.</p>
                                    <p>Through applying the Twelve Steps and the Twelve Traditions, we grow in love, tolerance, and respect for each other. This spiritual growth allows us to rise above prejudice, regardless of religion, ethnicity, economic status, age, gender, or sexual orientation, to carry the C.A. message. Though we are different, we are the same. Regardless of the author, we share the same story.</p>
                                    <p><b>Tradition One:</b> <i>"Our common welfare should come first; personal recovery depends upon C.A. unity."</i>.</p>
                                    <p>Recovery is possible—together.</p>
                                    <p>
                                    Unity preserves our C.A. Fellowship.<br></br>
                                    Unity preserves our legacy of Hope, Faith, and Courage.<br></br>
                                    Unity preserves our personal recovery, our reunited families, and our rediscovered dreams.
                                    </p>
                                    <p>Simple steps toward unity include:</p>
                                    <p>
                                        <ul>
                                            <li>Carrying the C.A. message;</li>
                                            <li>Encouraging the practice of love and tolerance of others;</li>
                                            <li>Sponsoring workshops, activities, and functions that promote unity;</li>
                                            <li>Enhancing communication among members by emphasizing principles before personalities;</li>
                                            <li>Encouraging participation in service by all members;</li>
                                            <li>Encouraging greeters to welcome newcomers at all levels;</li>
                                            <li>Encouraging group inventories.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="media-right is-hidden-mobile">
                                <img src="/img/literature/Unity.jpg" alt=""></img>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Unity